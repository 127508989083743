<template>
  <div class="code-container">
    <div class="code" ref="code">{{code}}</div>
    <button type="button" v-clipboard:copy="code"  v-clipboard:success="onCopy" class="copy-button"></button>
  </div>
</template>


<script>
import { toast } from 'vue3-toastify';
const toastSuccess = ''

export default {
  name: 'ShopItem',
  props: {
    code: {
      type: String,
      default: {}
    },
    toastSuccess: {
      type: String,
      default: {}
    },

  },
  data() {
    return {
      showModal: false,
    };
  },
  setup(props) {
    const onCopy = (e) => {
      toast( props.toastSuccess, {
        autoClose: 1000,
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }

    return { onCopy }
  },
};
</script>
