// eslint-disable-next-line
import { useAuthStore } from '@/store/auth';
import router from '@/router';

class Http {
  baseURL = process.env.VUE_APP_API_URL;
  csrfUrl = process.env.VUE_APP_API_URL;
  authUrl = process.env.VUE_APP_AUTH_URL;
  authSuff = process.env.VUE_APP_AUTH_URL_PROFILE ? process.env.VUE_APP_AUTH_URL_PROFILE : '';
  coockieUrl = '';
  // sessionCookie = process.env.VUE_APP_SESSION_COOKIE;
  serviceMode = process.env.VUE_APP_SERVICE_MODE;

  async fetchData(method, endpoint, data = null, type = null) {
    let url = ''
    if (endpoint == '/backend/sanctum/csrf-cookie')
      url = endpoint;
    else if(endpoint.includes('?'))
      url = this.baseURL + endpoint +'&'+ this.authSuff;
    else
      url = this.baseURL + endpoint +'?'+this.authSuff;
    const headers = new Headers();
    const credentials = 'include';
    // console.log(this.baseURL)
    if (!type) {
      headers.append('Content-Type', 'application/json');
    }
    headers.append('Accept', 'application/json');
    headers.append('credentials', 'include');
    headers.append('Access-Control-Allow-Origin', '*');
    const { token } = useAuthStore();
    const options = {
      method,
      credentials,
      headers,
    };
    if (data) {
      options.body = type === 'formData' ? data : JSON.stringify(data);
    }
    try {
      const response = await fetch(url, {
        ...options,
      });
      if (response.status >= 500 || response.status === 429) {
        router.push('/error');
        return false;
      }
      if (response.status === 422) {
        const errorText = await response.json();
        return {
          error: true,
          errorText,
        };
      }
      if (response.status === 401) {
        window.location.href = (this.authUrl);
      }
      if (response.status === 403) {
        const result = await response.json();
        const errorMessage = result.message;
      }
      if (response.status < 200 || response.status >= 300) {
        return false;
      }
      let result;
      if (endpoint == '/backend/sanctum/csrf-cookie'){
        result = await response;
      }
      else{
        result = await response.json();
      }
      if (this.serviceMode) {
        console.log(method, endpoint, data, result);
      }
      return result;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async get(endpoint) {
    const result = await this.fetchData('GET', endpoint, null);
    return result || false;
  }

  async post(endpoint, data = null, type = null) {
    const result = await this.fetchData('POST', endpoint, data, type);
    return result || false;
  }
}

export default new Http();
