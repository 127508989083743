<template>
  <div class="page-content home-page-content">
    <super-game-widget class="visible-sm"></super-game-widget>
    <main-categories></main-categories>
  </div>
</template>

<script>
import { RouterLink, RouterView } from 'vue-router'
import Modal from '@/components/modals/Modal.vue'
import { mapState, mapActions } from 'pinia';
import { useUserStore } from '@/store/user';
import superGameWidget from "@/components/home/superGameWidget.vue";
import mainCategories from "@/components/home/categories.vue";

export default {
  name: 'Nav',
  components: {
    RouterLink,
    RouterView,
    Modal,
    superGameWidget,
    mainCategories,
    // ModalPreview
  },
  data() {
    return {
      announceModal: false,
    };
  },
  computed: {
    ...mapState(useUserStore, ['userInfo']),
  },
  methods: {
    ...mapActions(useUserStore, ['updateGame', 'exitApp']),
    modalClose() {
      this.announceModal = false
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
        vm.updateGame()
    })
  }
};
</script>
