<template>
  <footer class="footer">
    <RouterLink to="/legal" class="footer-nav-item">{{ $t("terms") }}</RouterLink>
    <a href="https://static.beeline.ru/upload/images/politica22102021.pdf" class="footer-nav-item" target="_blank">{{ $t("policy") }}</a>
  </footer>

</template>

<script>
  import { RouterLink, RouterView } from 'vue-router'

  export default {
    name: 'AppFooter',
    components: {
      RouterLink,
      RouterView,
    }
  };
</script>
