<template>
    <Modal
      v-model="showModal"
      @close="confirmClose"
      class="modal-wrapper modal-wrapper-rule default-modal"
      :clickToClose="false"
      >
      <div class="ruleSlide__wrapper">
        
        <swiper
        :modules="modules"
        :slides-per-view="1" 
        :pagination="{ clickable: true }"
        :navigation="true">
          <swiper-slide>
                <p class="ruleSlide__title">Призы</p>
                  <p class="ruleSlide__text">Решай вопросы классической игры и получай ценные призы:
                    <ul class="ruleSlide__list">
                      <li>за 10 правильных ответов подряд — промокоды от партнёров билайна</li>
                      <li>за прохождение всей категории — призы в сервисах билайна</li>
                    </ul>
                  </p>
                <p class="ruleSlide__text">А  победа в СУПЕРИГРЕ принесет тебе самый крутой приз — промокод в интернет-магазине билайн!</p>
                <div class="ruleSlide__beelineBlock"><img src="@/assets/img/beeline.png" alt="Билайн" width="50" height="52"><span class="ruleSlide__yellowText">1 Гигабайт Интернета</span></div>
          </swiper-slide>


            <swiper-slide>
                <p class="ruleSlide__title">Классическая игра</p>
                <p class="ruleSlide__text ruleSlide__text--noMobileMargin">В каждом вопросе четыре варианта ответа, из которых только один правильный.</p>
                <p class="ruleSlide__text">Ответь на вопрос правильно за отведённое время и получи 10 мелокойнов, иначе у тебя потратится жизнь.</p>
                <div class="ruleslide__img"><img src="@/assets/img/ruleslide-examp-2.png" alt="Билайн" width="304" height="184"></div>
            </swiper-slide>

            <swiper-slide>
                <p class="ruleSlide__title">СУПЕРИГРА</p>
                <p class="ruleSlide__text">Доступна раз в сутки после прохождения ежедневных квестов.
                  <br/>Всего 15 вопросов, первая ошибка — игра заканчивается. У тебя есть 1 час! 
                  <br/>За каждые 5 правильных ответов ты получаешь гарантированные награды: 
                </p> 
                <p class="ruleSlide__text">
                  <ul class="ruleSlide__list">
                    <li>за 5 — 100 мелокойнов</li>
                    <li>за 10 — 350 мелокойнов</li>
                    <li>за 15 — 650 мелокойнов и 3 жизни</li>
                  </ul>
                </p>
                <div class="ruleslide__img ruleslide__img--desktop"><img src="@/assets/img/ruleslide-examp-3.png" alt="Билайн" width="304" height="72"></div>
                <div class="ruleslide__img ruleslide__img--mobile"><img src="@/assets/img/ruleslide-examp-3-mobile.png" alt="Билайн" width="304" height="82"></div>
            </swiper-slide>

          
          <swiper-slide>
              <p class="ruleSlide__title">Подсказки</p>
              <p class="ruleSlide__text">Не знаешь как ответить? Не беда! Воспользуйся подсказками:
                <ul class="ruleSlide__list">
                    <li>«Убрать один неверный ответ» — 30 мелокойнов</li>
                    <li>«50/50» — 50 мелокойнов</li>
                    <li>«Заменить вопрос» — 100 мелокойнов (только в СУПЕРИГРЕ)</li>
                  </ul>
                </p>
              <p class="ruleSlide__text ruleSlide__text--boldMobile"> В СУПЕРИГРЕ доступна только одна подсказка каждого вида.</p>
              <div class="ruleslide__img"><img src="@/assets/img/ruleslide-examp-4-mobile.png" alt="Билайн" width="304" height="82"></div>
              <!-- <div class="btnGroup">
                <button v-if="staticPrefix == 'http://meloquiz-p2.devel.via-mobi.com'" @click="exit" class="btn btn-primary">Выйти</button>
              </div> -->
          </swiper-slide>

      </swiper>
    </div>
    </Modal>
  </template>
  
  
  <script>
    import { useModal } from 'vue-final-modal'
    import Modal from '@/components/modals/Modal.vue'
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import { Pagination, Navigation } from 'swiper/modules';
    import { mapState, mapActions } from 'pinia';
    import { useUserStore } from '@/store/user';
    import 'swiper/css';
    import 'swiper/css/pagination';
    import 'swiper/css/navigation';

    const modules = [Pagination];

  export default {
    name: 'ruleModal',
    components: {
      Modal,
      Swiper, 
      SwiperSlide,
      Pagination
    },
    setup() {
      return {
        modules: [Pagination, Navigation],
      };
    },
    props: {
        showRuleModal: {
            type: Boolean,
            default: false,
        }
    },
    data () {
      return {
        currentSlide: 1,
      }
    }, 
    computed: {
      ...mapState(useUserStore, ['staticPrefix']),
        showModal () {
            return this.showRuleModal;
        }
    },
    methods: {
      ...mapActions(useUserStore, ['updateGame', 'exitApp']),
      exit() {
      this.exitApp();
    },
      confirmClose () {
        this.$emit('ruleModalConfirm');
      },
      nextSlide() {
        this.currentSlide = this.currentSlide === 4 ? 1 : this.currentSlide+1;
      },
      prevSlide() {
        this.currentSlide = this.currentSlide === 1 ? 4 : this.currentSlide-1;
      }
    },
  };
  </script>
  