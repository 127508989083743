<template>
    <div class="shop-page-tabsGroup">
      <button :class="['shop-page-tab shop-page-tab--coins', {'shop-page-tab--active' : currentType === 'coins'}]" @click="$emit('clickTab', 'coins')">Мелокойны</button>
      <button :class="['shop-page-tab shop-page-tab--lives', {'shop-page-tab--active' : currentType === 'lives'}]" @click="$emit('clickTab', 'lives')">Жизни</button>
    </div>
  </template>
  
  <script>
  
  export default {
    name: 'shopTabs',
    props: {
        currentType: {
            type: String,
            default: 'coins',
        },
    },
    data() {
      return {

      }
    }
  };
  </script>
  