<template>
  <div class="page-container">
    <ruleModal :showRuleModal="showRuleModal" @ruleModalConfirm="ruleModalConfirm"></ruleModal>
  </div>
</template>

<script>

import {RouterLink, RouterView} from 'vue-router'
import ruleModal from '@/components/modals/ruleModal.vue';

export default {
  name: 'AppHeader',
  components: {
    RouterLink,
    RouterView,
    ruleModal
  },
  data() {
    return {
      showRuleModal: true
    };
  },
  methods: {
    ruleModalConfirm() {
        this.$router.go(-1);
        this.showRuleModal = false;
      },
  },
};
</script>
