<template>
  <Modal
    v-model="showModal"
    @close="confirmClose"
    @confirm="goToLink"
    btnText="Активировать"
    class="default-modal default-modal--noTitle"
    >
    <div class="default-modal-mainContent">
        <img class="default-modal-logo"  :src="getImageUrl(currentItem.offer.partner.logoUrl)"  :alt="currentItem.offer.partner.title">
        <p class="default-modal-text">{{currentItem.offer.title}}</p>
        <p class="default-modal-text default-modal-text--list" v-html="currentItem.offer.instructionFormatted">
        </p>
        <div class="presentPopUp__btnGroup">
          <Code class="presentPopUp__code" :code="currentItem.value" toastSuccess="Скопировано в буфер обмена"></Code>
          <!-- <a  target="_blank" :href=currentItem.offer.url  class="btn btn-primary presentCard__btn">Активировать</a> -->
        </div>
      </div>
  </Modal>
</template>


<script>
import { useModal } from 'vue-final-modal'
import Modal from '@/components/modals/Modal.vue'
import Code from '@/components/promo/codeBlock.vue'

export default {
  name: 'PresentModal',
  components: {
    Modal,
    Code
  },
  props: {
    showPresentModal: {
      type: Boolean,
      default: true,
    },
    currentItem: {
      type: Object,
      default: {
          offer: {
              title: '',
              url: '',
              instructionFormatted: '',
          },
      value: '',
      }
    }
  },
  data () {
    return {
    }
  },
  computed: {
     showModal () {
        return this.showPresentModal;
    },
  },
  methods: {
    confirmClose () {
      this.$emit('confirmClose');
    },
    goToLink () {
      window.open(this.currentItem.offer.url, "_blank");
    },
    getImageUrl(url) {
        return new URL(url, import.meta.url).href
    },
  },
};
</script>
