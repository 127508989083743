import { defineStore } from 'pinia';
import Http from '@/Http';
import router from '@/router'

const progressDelays = ['2000', '5000', '10000', '15000', '20000', '25000', '600000'];
let progressCounter = -1, progressTimneout;
const staticPrefix = process.env.VUE_APP_URL;
const authSuff = process.env.VUE_APP_AUTH_URL_PROFILE ? process.env.VUE_APP_AUTH_URL_PROFILE : '';

export const useUserStore = defineStore('user', {
  state: () => ({
    currentCategory: null,
    dataLoaded: false,
    userInfo: {
      supergame: {
        progress: {
          current: 0,
          max: 15
        },
        quests_progress: {
          answers_count: 0,
          questions_count: 0
        }
      }
    },
    currentGame: {},
    staticPrefix: process.env.VUE_APP_URL,
    promo: [],
    shop: {
      data: {
        coins: [],
        lives: []
      }
    },
    shopBaseType: 'coins',
    storeBackUrl: '',
    serviceMode: false,
    tipsList: [],
    itsTrial: false,
    itsTrialEnded: false,
    promoCount: 0,
    noLivePopUp: false,
    noClosePopUp: false,
    startAnimationSuperGame: false,
    itsQuest: localStorage.getItem('itsQuest') ? JSON.parse(localStorage.getItem('itsQuest')) : false,
    attemptsToSubscribePopup: 0,
  }),
  getters: {
    // sudokuData: (state) => sudokuGamestate.games.sudoku,
  },
  actions: {
    updateAttempts(reset){
      if (reset)
        this.attemptsToSubscribePopup = 0;
      else
        this.attemptsToSubscribePopup++;

      localStorage.setItem(`attemptsToSubscribePopup-${this.userInfo.msisdn}`, this.attemptsToSubscribePopup);
    },
    getAttemps(){
      this.attemptsToSubscribePopup = localStorage.getItem(`attemptsToSubscribePopup-${this.userInfo.msisdn}`)
      return this.attemptsToSubscribePopup
    },
    toggleAnimationSuperGame(amountCoins = 0) {
      this.startAnimationSuperGame = !this.startAnimationSuperGame;
      let counts = 0;
      if (this.startAnimationSuperGame && amountCoins) {
        const quantityAdded = Math.floor(amountCoins/3);
        const remainder = amountCoins - (quantityAdded * 3);
        const countsIncount = setInterval(() => {
        counts++;
        if (counts > 3 && counts < 6) {
          this.userInfo.coins += quantityAdded;
        }
        if (counts === 6) {
          this.userInfo.coins += quantityAdded + remainder;
          clearInterval(countsIncount);
        }
        }, 300)
      }
    },
    closeNoLivePopUp () {
      this.noLivePopUp = false;
    },
    changeShopType(type){
      if (type)
        this.shopBaseType = type;
    },
    showNoLivePopUp () {
      if (this.userInfo.lives > 0) {
        return;
      }
       this.noLivePopUp = true;
    },
    noClosePopUpToggle(value = false) {
      this.noClosePopUp =  value;
    },
    setCurrentCategory(item){
      this.userInfo.categories.forEach((el) => {
        if (el.id === item.id) {
          this.currentCategory = el;
        }
      })

    },
    itsQuestToggle(value = false) {
      this.itsQuest = value;
      localStorage.setItem('itsQuest', this.itsQuest);
    },
    resetCurrent() {
      this.currentGame = {};
    },
    checkDaily() {
      return this.userInfo.bonus_given
    },
    setBackUrl(url) {
      this.storeBackUrl = url;
    },
    async getUserInfo(isActiveGame) {
      const result = await Http.get('/profile');
      if (result) {
        this.dataLoaded = true;
        this.userInfo = result.data;
        this.promoCount = this.userInfo.promocodesCount;
        if(isActiveGame){
          this.userInfo.categories.forEach((el) => {
            if (el.id === this.currentCategory.id)
              this.currentCategory = el;
          })
        }
        if (this.userInfo.trial_lifetime) {
          this.itsTrial = true;
        }

        if (!this.userInfo.is_subscribed && !this.userInfo.trial_lifetime) {
          this.itsTrialEnded = true;
          // window.location.href = '/backend/subscribe_web';
        }

      }
    },
    async startGame(id, cont) {
      this.currentGame = {};
      if(!cont){
        const result = await Http.get(`/question?category_id=${id}`);
        if (result) {
          this.currentGame = {};
          this.currentGame = result.data;
        }
        else {
          router.push("/");
        }
      }
      else{
        await new Promise(resolve => setTimeout(resolve, 200));
        const result = await Http.get(`/question?category_id=${id}`);
        this.currentGame = {};
        this.currentGame = result.data;
      }
    },
    updateGame(game){
      this.currentGame = {};
      if (game){
        this.currentGame = game;
      }
    },
    async exitApp() {
      const result = await Http.get(`/logout`);
      window.location.href = '/backend/auth';

    },
    updateUserInfo(obj,data){
        this.userInfo[obj] = data;
    },
    updateProgress(data){
      if (data)
        this.userInfo.supergame.progress.current = data;
    },
    async startSuperGame(cont) {
      if(!cont){
        const result = await Http.get(`/supergame/question`);
        if (result) {
          this.currentGame = {};
          this.currentGame = result.data;
        }
        else {
          // router.push("/");
        }
      }
      else{
        await new Promise(resolve => setTimeout(resolve, 200));
        const result = await Http.get(`/supergame/question`);
        this.currentGame = {};
        this.currentGame = result.data;
      }
    },
    async checkAnswer(data) {
      const result = await Http.post('/question', data);
      if (result) {
        return result
      }
    },
    async checkAnswerSuper(data) {
      const result = await Http.post('/supergame/question', data);
      if (result) {
        return result
      }
    },
    async getPromo() {
      const result = await Http.get('/promocodes');
      if (result) {
        this.promo = result;
      }
    },
    async getTips() {
      const result = await Http.get('/tipsOptions');
      if (result) {
        this.tipsList = result.data;
      }
    },
    async getShop() {
      const result = await Http.get('/store');
      if (result) {
        this.shop=result;
      }
    },
    async storyItem(data) {
      const result = await Http.post('/store', data);
      if (result) {
        if (result.data.isSuccess)
          this.userInfo.coins = result.data.userCoins
          this.userInfo.lives = result.data.userLives

        return result
      }
    },
    async checkProgress(data) {
      if (progressCounter < progressDelays.length){
        // clearTimeout(progressTimneout);
        const result = await Http.post('/purchase-check', data);
        if (result.data.inProgress) {
          progressCounter++;
          await new Promise(resolve => setTimeout(resolve, progressDelays[progressCounter]));
          await this.checkProgress(data);
        }
        else if(result.data.isSuccess){
          this.userInfo.coins = result.data.userCoins
          this.userInfo.lives = result.data.userLives
        }
      }
    },
    async buyTip(data,category) {
      const result = await Http.post(`/question/tip?category_id=${category}`, data);
      if (result) {
        this.userInfo.coins = result.data.coins;
        this.currentGame.tips_state = result.data.tips_state
        this.currentGame.answers.forEach((el) => {

          for (const [key, value] of Object.entries(result.data.state.tips)) {

            if (value.answer_ids.includes(el.id)){
              el.disabled = true;
            }
          }

        })

        return result;
      }
    },
    async loseGame() {
      const result = await Http.get(`/supergame/terminate-session`);
    },
    async buyTipSuper(data) {
      const result = await Http.post(`/supergame/question/tip`, data);
      if (result) {
        this.userInfo.coins = result.data.coins;
        this.currentGame.answers.forEach((el) => {

          for (const [key, value] of Object.entries(result.data.state.tips)) {
            if (key!=5){
              if (value.answer_ids.includes(el.id)){
                el.disabled = true;
              }
            }
            else{
              if (data.tip_id === 5)
                this.currentGame = value
            }
          }

        })

        this.currentGame.tips_state = result.data.tips_state

        return result;
      }
    },
    async logOut() {
      const result = await Http.get(`/logout`);
      if (result.redirect)
        window.location.href = result.redirect
    },
  },
});
