import { ref, computed } from 'vue'
import { defineStore } from 'pinia';

export const useMainStore = defineStore('main', {
  state: () => ({
    modalVisible: false,
    modal: {},
    isMobile: true,
    dailyReward: false,
  }),
  getters: {
  },
  actions: {
    hideModal() {
      this.modalVisible = false;
      this.modal = {};
    },
    setDeviceAsDesktop() {
      this.isMobile = false;
    },
    buyConfirm() {
      return ( Math.floor(Math.random() * (1 - 0 + 1) + 0) === 1 ? true : false )
    },
  },
});
