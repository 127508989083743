<template>
  <div class="page-content legal-page-content">
    <div class="legalWrap">
    <div class="legal-title">Пользовательское соглашение сервиса</div>
    <button @click="$router.back()" class="go-back go-back-legal">
      <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.26033 6.95063L10.3824 2.69325L8.12965 0.375519L0.141113 8.59441L8.12965 16.8133L10.3824 14.4956L6.26033 10.2382H19.4818V6.95063H6.26033Z" fill="#8C36BD"/>
      </svg>
    </button>
    </div>
    <div class="legal-text">
      <p class="legal-text-title">1. Общие положения</p>
      <p>1.1. Организатором сервиса «Мелоквиз» (далее – Сервис) является ПАО «ВымпелКом» (Российская Федерация,
        127083, г. Москва, ул. 8 марта, дом 10, строение 14; ИНН 7713076301; ОГРН 1027700166636) (далее –
        Администрация Сервиса).</p>
      <p>1.2. Соглашение регулирует отношения между Администрацией Сервиса и Пользователем Сервиса (далее –
        Пользователь) в связи с использованием Сервиса Пользователем и участием Пользователя в стимулирующих
        мероприятиях, проводимых Администрацией Сервиса в рамках Сервиса в соответствии с требованиями, изложенными в
        разделе 5 Соглашения.</p>
      <p>1.3. Соглашение является публичной офертой, приняв которую Пользователь становится пользователем Сервиса.
        Регистрируясь в Сервисе, либо направляя запрос в Сервис, а также осуществляя регистрацию/авторизацию в
        Сервисе, использование Сервиса и/или Сайта Сервиса, Пользователь соглашается с условиями Соглашения, включая
        все приложения к Соглашению, с учетом дополнений и изменений Соглашения, а также дает свое добровольное,
        информированное, сознательное согласие на обработку его персональных данных и иной персональной информации в
        соответствии с Соглашением и Политикой обработки персональных данных в ПАО «ВымпелКом», размещенной и/или
        доступной на <a href="https://moskva.beeline.ru/customers/legal/userdata/">https://moskva.beeline.ru/customers/legal/userdata/</a>.
      </p>
      <p>1.4. Обязательным условием заключения Соглашения является полное и безоговорочное принятие и соблюдение
        Пользователем Политики обработки персональных данных в ПАО «ВымпелКом», размещенной и/или доступной на <a
            href="https://moskva.beeline.ru/customers/legal/userdata/">https://moskva.beeline.ru/customers/legal/userdata/</a>,
        являющейся неотъемлемой частью Соглашения и других ссылающихся на нее документов.</p>
      <p>1.5. В рамках Сервиса Пользователю предоставляется удаленный доступ через сеть Интернет к программам для ЭВМ
        и/или базам данных, реализующим функционал интеллектуальных мобильных игр, по правилам которых участникам
        предлагается собрать пазл, разгадать японский кроссворд и проч. (далее – игры). Правила игр определяются
        Администрацией Сервиса. Игры не являются азартными играми, пари, лотереями или иными основанными на риске
        мероприятиями.</p>

      <p class="legal-text-title">2. Порядок подключения Сервиса</p>
      <p>2.1. Сервис доступен Пользователям - абонентам оператора связи ПАО «ВымпелКом» (далее также - Оператор) -
        физическим лицам всех регионов РФ, в которых имеется покрытие сети Оператора, обслуживающимся на тарифных
        планах предоплатной и постоплатной системы расчетов, с учетом ограничений, предусмотренных Соглашением.</p>
      <p>Подключение Сервиса осуществляется посредством отправки SMS-сообщения с любым текстом (кроме Стоп/Stop) на
        номер 9303, отправки запроса на подключение Сервиса из мобильного приложения билайн, с сайта beeline.ru,
        включая субдомены или сайта krossvord.beeline.ru, включая субдомены (далее – Сайт Сервиса) или иными
        способами, информацию о которых Администрация Сервиса размещает на страницах сайта beeline.ru или
        krossvord.beeline.ru (включая субдомены), непосредственно в интерфейсах Cервиса, в СМИ и других публичных
        источниках, включая ресурсы в сети Интернет, в адресных информационных сообщениях, направляемых Администрацией
        с использованием собственных технологических средств (SMS, USSD, DSTK, Flash-инфо и др.), или иными способами
        с последующим выполнением инструкций, направляемых в SMS-сообщениях или иными способами.</p>

      <p class="legal-text-title">3. Стоимость предоставления Сервиса. Внутрисервисные ценности</p>
      <p>3.1. Стоимость предоставления Сервиса в течение подписочного периода (сутки) составляет 15 рублей с НДС в сутки.
        Списание денежных средств с абонентского счета Пользователя происходит каждый день. В случае
        неполучения от Пользователя отказа от Сервиса, срок предоставления Сервиса услуги автоматически продлевается
        каждый раз на сутки при условии достаточного количества средств на лицевом счете до момента самостоятельного
        отключения Сервиса Пользователем. Отправка SMS-сообщений на номер 9303 не тарифицируется в домашней сети, но
        тарифицируется в роуминге в соответствии со стоимостью отправки SMS-сообщения по тарифному плану Пользователя.
        Мобильный Интернет-трафик, потребленный Пользователем при использовании Программного продукта и пользовании
        услугой «Подписка», тарифицируется в соответствии с тарифным планом Пользователя.</p>
      <p>3.2. В Сервисе предусмотрена возможность приобретения внутрисервисных ценностей - «Кристаллов», которые
        позволяют получить подсказки в играх, доступных в Сервисе. Стоимость Кристаллов зависит от их количества:</p>
      <p>10 «Кристаллов» - 5 рублей с НДС,</p>
      <p>50 «Кристаллов» - 20 рублей с НДС,</p>
      <p>300 «Кристаллов» - 100 рублей с НДС,</p>
      <p>1 000 «Кристаллов» - 250 рублей с НДС,</p>
      <p>2 500 «Кристаллов» - 500 рублей с НДС.</p>

      <p>3.3. Оплата за приобретение внутрисервисных ценностей («Кристаллов») осуществляется Пользователем отдельно от
        стоимости предоставления Сервиса, не является обязательным условием использования Сервиса и осуществляется
        Пользователем по его собственному усмотрению. Оплата за приобретение внутрисервисных ценностей осуществляется
        путем списания Администрацией Сервиса денежных средств в соответствующей сумме с лицевого счета абонента в
        биллинговой системе Оператора. Пользователь самостоятельно определяет количество приобретаемых «Кристаллов» и
        инициирует оплату посредством специальных инструментов, реализованных в интерфейсе Сервиса. В случае
        недостаточности денежных средств на лицевом счету Пользователя для оплаты полного количества «Кристаллов»,
        выбранного Пользователем, денежные средства с лицевого счета не списываются, внутрисервисный ценности
        («Кристаллы») Пользователю не начисляются. Частичная оплата выбранного Пользователей количества «Кристаллов»
        не допускается.</p>

      <p>3.4. Внутрисервисная ценность («Кристалл») представляет собой единицу объема прав Пользователя на
        использование дополнительного функционала Сервиса по предоставлению Пользователям подсказок при прохождении
        игр в Сервисе.</p>

      <p>3.5. «Кристаллы» учитываются Администрацией Сервиса в учетной записи Пользователя в Сервисе, имеющей привязку
        к абонентскому номеру телефона Пользователя, указанному при подключении Пользователем подписки на Сервис.</p>

      <p>3.6. Стоимость «Кристаллов» Пользователю не возвращается, в том числе в случае отключения Пользователем
        подписки на Сервис. «Кристаллы» могут быть использованы Пользователем в соответствии с логикой и сценарием
        Сервиса в течение всего срока предоставления Сервиса без ограничений.</p>

      <p>3.7. Внутрисервисная ценность («Кристалл») не является средством платежа, аналогом денежных средств, не
        является самостоятельным объектом имущественных прав и не может являться предметом сделок. Внутрисервисная
        ценность («Кристалл») не может быть передана другим лицам, в том числе другим пользователям Сервиса, по
        договорам купли-продажи, дарения, мены, уступки права или по иным сделкам.</p>

      <p>3.8. Пользователь может приобрести «Кристаллы» в обмен на игровые очки (в тексте Соглашения именуемые также
        «очки»), начисленные Пользователю за прохождение игр и/или выполнение иных действий в Сервисе. Правила и
        условия обмена очков на «Кристаллы» размещаются на соответствующих страницах и/или в разделах Сайта Сервиса и
        в интерфейсах Сервиса. Информация о приобретении «Кристаллов», списании очков отображается в профиле
        Пользователя на Сайте Сервиса.</p>
      <p>В тексте Соглашения «очки» означает условные единицы измерения, отображаемые в профиле Пользователя в
        Сервисе, характеризующие степень активности Пользователя по использованию Сервиса и используемые
        Администрацией Сервиса для определения объема прав Пользователя по использованию дополнительных функциональных
        возможностей Сервиса.</p>

      <p class="legal-text-title">4. Порядок использования Сервиса</p>
      <p>4.1. Регистрируясь в Сервисе, либо направляя запрос в Сервис, а также осуществляя регистрацию/авторизацию в
        Сервисе, использование Сервиса и/или Сайта Сервиса, Пользователь, принимая условия Соглашения:</p>

      <p>1) Использует Сервис на свой собственный риск. Сервис предоставляется «как есть». Администрация Сервиса не
        принимает на себя никакой ответственности, в том числе за соответствие Сервиса целям Пользователя.</p>
      <p>2) Гарантирует, что его возраст не менее 18 лет.</p>
      <p>3) Принимает к сведению и соглашается с тем, что Сервис может содержать материалы, ориентированные только на
        совершеннолетних Пользователей.</p>
      <p>4) Обязуется использовать Сервис в соответствии с законодательством Российской Федерации и Соглашением.</p>
      <p>5) Подтверждает, что проинформирован Администрацией Сервиса о том, что Пользователь может в любое время
        перестать пользоваться Сервисом без объяснения причин, следуя инструкциям, указанным в Соглашении.</p>
      <p>6) Соглашается с тем, что Сервис, включая программы для ЭВМ и базы данных, удаленный доступ к которым
        предоставляется Пользователю в рамках Сервиса, предназначен исключительно для личного некоммерческого
        использования.</p>
      <p>7) Имеет право подключить и использовать Сервис с использованием только одного абонентского номера телефона в
        сети связи Оператора. В случае выявления Администрацией Сервиса подключения или использования абонентом
        Сервиса с использованием второго и каждого последующего абонентского номера, Администрация Сервиса вправе
        прекратить предоставление Пользователю Сервиса с использованием всех абонентских номеров, кроме одного.</p>

      <p>4.2. Не допускаются или исключаются на любом этапе аккаунты в Сервисе, зарегистрированные Пользователем с
        использованием каких-либо ресурсов программирования. Пользователь не вправе использовать для участия в Сервисе
        или Акции программное обеспечение или механические, или электронные приборы и/или устройства, которые
        позволяют автоматически вносить данные на Сайте Сервиса, равно как и генерировать такие данные. Учетная
        запись, в которой указаны номера мобильных телефонов и электронные почты, зарегистрированные способами,
        указанными в настоящем пункте, Администрацией Сервиса аннулируются, а предоставление Сервиса использующим их
        Пользователем – прекращается, в том числе прекращается участие Пользователей в Акциях. </p>

      <p>4.3. Для получения доступа к Сервису с использованием Мобильного приложения билайн мобильное устройство
        Пользователя должно поддерживать операционные системы iOS (начиная с версии 14.0.0) или Android (начиная с
        версии 7.0.0). Для получения доступа к web-интерфейсу Сервиса устройство Пользователя должно поддерживать
        последние версии браузеров Safari, Firefox, Chrome, Opera. Сайт Сервиса и игры могут не запускаться в старых
        версиях указанных браузеров и браузере Internet Explorer.</p>

      <p>4.4. Для начала пользования Сервисом Пользователю необходимо воспользоваться одним из интерфейсов Сервиса,
        используя номер телефона в качестве идентификатора для входа. В процессе первого подключения Сервиса с
        указанием номера телефона осуществляется регистрация Пользователя в Сервисе. В дальнейшем Пользователь
        авторизуется в Сервисе путем указания номера телефона, если номер телефона автоматически не отображается в
        форме авторизации. Авторизация Пользователя в Сервисе осуществляется по номеру телефона в сети связи
        Оператора, с использованием которого Пользователем подключена подписка на Сервис.</p>

      <p class="legal-text-title">5. Стимулирующие мероприятия</p>

      <p>5.1. Настоящим разделом Соглашения регулируется порядок организации и проведения Администрацией Сервиса
        стимулирующих мероприятий в рамках Сервиса (на Сайте Сервиса), направленных на привлечение внимания
        Пользователей к Сервису, повышение лояльности существующих Пользователей и привлечение новых Пользователей к
        Сервису.</p>

      <p>5.2. Мероприятие не является лотереей или иной основанной на риске игрой. Пользователи не несут имущественных
        рисков, связанных с участием в Мероприятии. Участие не связано с внесением Участниками платы. Поощрения
        формируются исключительно за счет средств Администрации Сервиса или привлеченных Партнеров Акции.</p>

      <p>5.3. Организатором Мероприятия является ПАО «ВымпелКом» (Российская Федерация, 127083, г. Москва, ул. 8
        марта, дом 10, строение 14; ИНН 7713076301; ОГРН 1027700166636).</p>

      <p>5.4. Участниками, на указанных ниже условиях могут стать физические лица, достигшие 18 лет и являющиеся
        Пользователями Сервиса (далее – Участник/Участники). Не допускается участие в Мероприятии пользователей услуг
        связи Оператора, не являющихся абонентами Оператора, в том числе не допускается участие физического лица,
        которому абонентский номер предоставлен в пользование абонентом – юридическим лицом или индивидуальным
        предпринимателем.</p>

      <p>5.5. Для участия в Мероприятии Пользователю, соответствующему установленным в Соглашении требованиям,
        необходимо выполнить предусмотренные в Соглашении условия и действия, неразрывно связаны с использованием им
        Сервиса. Использование Сервиса, в том числе совершение любых действий по участию в Мероприятии,
        предусмотренных Соглашением в качестве условий участия в Мероприятии, автоматически означает согласие
        Участника с правилами и условиями проведения Мероприятия, изложенными в настоящем разделе и других разделах
        Соглашения, а также в приложениях к Соглашению и/или размещенных на соответствующих страницах Сайта Сервиса/ в
        мобильном приложении билайн.</p>

      <p>5.6. Мероприятия могут проводиться Администраций Сервиса в течение срока предоставления Сервиса.</p>

      <p>5.7. Подробная информация о поощрениях, предоставляемых Администрацией Сервиса или партнерами Мероприятия
        Участникам, признанным победителями, включая количество поощрений, условия их получения и ограничения,
        размещается в Приложении № 1 к Соглашению и на Сайте Сервиса в разделе «Призы».</p>

      <p>5.8. Участник имеет право на получение поощрения в случае признания его победителем в соответствии с
        правилами Мероприятия, изложенными в Соглашении и на Сайте Сервиса. Выплата денежного эквивалента стоимости
        приза не производится.</p>

      <p>5.9. Количество призов ограничено количеством возможных победителей в Мероприятии, указанным в п. 5.15.
        Соглашения.</p>

      <p>5.10. Участниками Мероприятия являются все Пользователи, соответствующие требованиям п. 5.4. Соглашения,
        которые в прошедшем календарном месяце прошли минимум один уровень/раунд в рамках функционала Сервиса.</p>

      <p>5.11. Поощрения могут быть использованы (активированы) в течение 2 (двух) месяцев со дня их предоставления
        Участнику Администрацией Сервиса или партнером Мероприятия. В случае, если поощрения не использованы (не
        активированы) в течение указанного срока, Участник утрачивает право воспользоваться ими. Возмещение Участнику
        стоимости поощрений и/или предоставление какой-либо компенсации не производится.</p>

      <p>5.12. В случае, если Участник признан победителем Мероприятия, имеющим право на получение поощрения,
        информация об этом доступна соответствующему Участнику в профиле Пользователя на Сайте Сервиса. В случае, если
        вручение поощрения осуществляется каким-либо иным способом, помимо предоставления Администрацией Сервиса
        поощрения в распоряжение Участника средствами Сервиса (начисления бонусных рублей и проч.), информация о
        порядке и способе получения поощрения доводится до сведения Участника в его профиле Пользователя на Сайте
        Сервиса.</p>

      <p>5.13. Администрация Сервиса не несет ответственности за качество товаров и/или услуг партнеров Мероприятия, а
        также за полноту и достоверность предоставленной партнером информации о поощрениях и соответствие такой
        информации фактическим условиям получения товаров и услуг партнеров Мероприятия.</p>

      <p>5.14. Для определения победителя в целях вручения поощрения Администрация Сервиса формирует сводную базу
        Пользователей, решивших хотя бы один уровень/раунд в Сервисе в прошедшем календарном месяце и имеющих активную
        подписку на Сервис на момент составления сводной базы Пользователей Сервиса (в хронологическом порядке по дате
        и времени завершения игры по прохождению раунда) от самого первого до самого последнего Пользователя (далее –
        База Участников). База Участников формируется по состоянию на 00 ч. 00 мин. (по московскому времени) суток,
        следующих после окончания соответствующего месяца, по результатам которого определяются победители
        Мероприятия. В том случае, если Пользователь не выполнил полностью предусмотренные п. 5.4., 5.10. Соглашения
        условия, такой Пользователь не включается в Базу Участников.</p>

      <p>5.15. Поощрения вправе получить 500 (Пятьсот) Участников (в тексте Соглашения – Победитель/ Победители),
        выполнивших условия, указанные в п. 5.4., 5.10. Соглашения, и имеющих активную подписку на Сервис как на
        момент составления Базы Участников, так и на момент предоставления поощрения, при условии, что порядковый
        номер таких Участников в Базе Участников будет равен значению Y, для определения которого используются формулы
        1 и 2:</p>

      <p>Формула 1.</p>
      <p>N = Z/501, где</p>
      <p>Z - общее количество всех Участников Мероприятия (в хронологическом порядке по дате и времени завершения игры
        по прохождению раунда) от самого первого до самого последнего Участника, выполнившего условия Мероприятия),
        включенных в Базу Участников.</p>

      <p>N, рассчитываемая по формуле 1, указанной выше, округляется до целого числа в меньшую сторону. В случае если
        величина Z ≤ 500, то N=1.</p>

      <p>Формула 2.</p>
      <p>Y = N * X, где</p>
      <p>Y – порядковый номер Участника Мероприятия, признанного Победителем, имеющего право на получение
        определенного Поощрения, имеющего порядковый номер, присвоенный Администрацией Сервиса.</p>
      <p>X – порядковый номер Поощрения от 1 до 500 (присваивается Администрацией Сервиса до начала определения списка
        Участников, признанных победителями).</p>
      <p>N – величина, определяемая по формуле 1.</p>

      <p>Определение Участника Мероприятия, имеющего право на получение поощрения, производится Администрацией Сервиса
        в отношении каждого конкретного поощрения в соответствии с формулами 1 и 2, приведенными в п. 5.15.
        Соглашения.</p>

      <p>Порядковый номер Участника Мероприятия, признанного Победителем, соответствующий значению, определенному по
        формулам 1 и 2, приведенным в п. 5.15. Соглашения, соотносится с порядковым номером соответствующего
        поощрения, использованным в расчетах по формулам 1 и 2.</p>

      <p>5.16. В случае, если Пользователь, признанный Победителем, не соответствует требованиям и условиям,
        предусмотренным Соглашением, а также в случае нарушения Пользователем Соглашения, Администрация Сервиса вправе
        не предоставлять такому Пользователю поощрение. В этом случае у Пользователя не возникает право на получение
        поощрения и Администрация Сервиса распоряжается поощрением по своему усмотрению.</p>

      <p>5.17. В случае отказа Пользователя от получения поощрения и/или от предоставления персональных данных,
        необходимых Администрации Сервиса в целях исполнения обязанностей налогового агента по налогу на доходы
        физических лиц, установленных Налоговым кодексом РФ, Администрация Сервиса вправе не предоставлять такому
        Пользователю поощрение. В этом случае право Пользователя на получение поощрения считается не возникшим и
        Администрация Сервиса распоряжается поощрением по своему усмотрению.</p>

      <p>5.18. Выдача поощрения Пользователю, признанному Победителем, не имеющему активной подписки на Сервис на
        момент предоставления поощрения, не производится. В этом случае у Пользователя не возникает право на получение
        поощрения и Администрация Сервиса распоряжается поощрением по своему усмотрению.</p>

      <p>5.19. Администрация Сервиса определяет Победителей Мероприятия среди Участников, включенных в Базу Участников
        по результатам использования Сервиса за прошедший календарный месяц, в течение 10 рабочих дней по окончании
        соответствующего месяца, за который определяются Победители Мероприятия, и в указанный срок доводит до
        сведения победителей информацию о поощрениях, а также предоставляет победителям поощрения (если иной срок
        предоставления поощрения не указан в Приложении к Соглашению и/или в условиях использования Поощрения, которые
        доводятся до Участника в его Профиле Пользователя).</p>

      <p>5.20. Информация о признании Пользователя Победителем Мероприятия и поощрении, на получение которого
        Пользователь имеет право, а также условиях его получения, доводится до сведения Пользователя посредством
        интерфейсов Сервиса, в том числе размещается в профиле Пользователя на Сайте Сервиса на соответствующих
        страницах/ в разделах Сайта Сервиса.</p>

      <p>5.21. Принимая Соглашение, в том числе используя Сервис и/или участвуя в Мероприятии, Пользователь
        соглашается с тем, что выигрыши (поощрения) Пользователя в конкурсах, играх и иных мероприятиях в целях
        рекламы товаров (работ, услуг), проводимых в рамках Сервиса, признаются доходом физического лица в порядке
        статей 41, 209 Налогового кодекса РФ и облагаются налогом на доходы физических лиц по ставке 35% (п.2 ст. 224
        Налогового кодекса РФ).</p>
      <p>В соответствии с п. 28 ст. 217 Налогового кодекса РФ не подлежат налогообложению доходы физических лиц, не
        превышающие 4000 (Четыре тысячи) рублей, полученные налогоплательщиком за налоговый период в виде любых
        выигрышей и поощрением в проводимых конкурсах, играх и других мероприятиях в целях рекламы товаров (работ,
        услуг). В случае, если сумма полученных одним и тем же физическим лицом выигрышей, поощрений, полученных в
        проводимых конкурсах, играх и иных мероприятиях в целях рекламы товаров (работ, услуг), превысит в налоговом
        периоде (календарном году) 4000 (Четыре тысячи) рублей, лицо, предоставившее денежные средства, будет признано
        налоговым агентом, в связи с чем на него будут возложены обязанности, предусмотренные для налоговых агентов
        ст. 226 Налогового кодекса РФ. Если у налогового агента отсутствует возможность удержать налог у физического
        лица при выплате выигрышей, налоговый агент обязан направить налоговому органу сообщение о невозможности
        удержания налога и его сумме в срок не позднее одного месяца со дня окончания соответствующего налогового
        периода (пп. 2 п. 3 ст. 24, п. 5 ст. 226 Налогового кодекса РФ).</p>

      <p>5.22. В случае получения Пользователем от Администрации Сервиса или Партнера Мероприятия доходов в
        натуральной форме (в том числе в виде промокодов на скидки, подарочных сертификатов и проч.) выплаты денежных
        средств, из которых Администрация Сервиса или Партнер Мероприятия может удержать налог на доходы физических
        лиц, не происходит. В указанных случаях применяются положения пункта 5 статьи 226 Налогового кодекса РФ, в
        соответствии с которыми при невозможности удержать у налогоплательщика исчисленную сумму налога Администрация
        Сервиса (или Партнер Акции), являющиеся налоговыми агентами, обязаны не позднее одного месяца с даты окончания
        налогового периода, в котором возникли соответствующие обстоятельства, письменно сообщить налогоплательщику и
        налоговому органу по месту своего учета о невозможности удержать налог и сумме налога.</p>
      <p>В этом случае с учетом подпункта 4 пункта 1 статьи 228 Налогового кодекса РФ исчисление, декларирование и
        уплату налога на доходы физических лиц с указанных доходов Пользователи осуществляют самостоятельно в порядке,
        предусмотренном статьями 228 и 229 Налогового кодекса РФ. Неисполнение указанных требований Налогового кодекса
        РФ является нарушением налогового законодательства Российской Федерации, за которое Пользователь может быть
        привлечен к установленной Налоговым кодексом РФ ответственности.</p>

      <p>5.23. В целях учета поощрения, предоставляемых Пользователю, а также исполнения обязанностей налогового
        агента и соблюдения налогового законодательства Администрация Сервиса вправе запросить у Пользователя
        персональные данные, включая фамилию, имя, отчество, сведения о дате, месте рождения, номер паспорта, дату
        выдачи, наименование выдавшего органа, код подразделения, ИНН (идентификационный номер налогоплательщика),
        адрес по месту регистрации (постановки на налоговый учет), номер телефона (абонентский номер в сети связи ПАО
        «ВымпелКом»), контактный номер телефона, электронную почту.</p>
      <p>В случае отказа Пользователя в предоставлении Администрации Сервиса указанных данных или предоставления
        недостоверных данных, Администрация Сервиса вправе отказать Пользователю в выплате выигрыша, в том числе в
        случае, если общий размер выигрышей, выплаченных Пользователю в текущем календарном году, не превысил 4 000,00
        (Четыре тысячи) рублей. Администрация Сервиса оставляет за собой право осуществлять проверку предоставленных
        Пользователем персональных данных в соответствии с Соглашением и законодательством Российской Федерации.</p>

      <p class="legal-text-title">6. Поощрения</p>
      <p>6.1. При прохождении игр, совершении определенных действий по использованию Сервиса Пользователю
        Администрацией Сервиса могут предоставляться поощрения в рамках игрового процесса, логики и сценария Сервиса,
        реализованные как часть функционала Сервиса, или поощрения в виде промокодов на скидки в Интернет-магазинах/
        розничных торговых точках Администрации Сервиса (или партнеров Администрации Сервиса) или иных ценных призов
        (далее – Поощрения).</p>
      <p>6.2. Пользователю могут предоставляться следующие Поощрения:</p>
      <p>- начисление очков, которые в дальнейшем могут быть использованы для приобретения внутрисервисных ценностей
        («Кристаллов»),</p>
      <p>- поощрения в виде промокодов на скидки в Интернет-магазинах и другие ценные призы, которые предоставляются
        Администрацией Сервиса или третьими лицами,</p>
      <p>- фрагменты пазла для прохождения игр в Сервисе,</p>
      <p>- дополнительные возможности в Сервисе и другие Поощрения, определяемые по единоличному усмотрению
        Администрации Сервиса.</p>

      <p>6.3. Количество Поощрений ограничено. Перечень и количество Поощрений определяются Администрацией Сервиса по
        ее единоличному усмотрению. Поощрение, на получение которого имеет право Пользователь в каждом конкретном
        случае, определяется Администрацией Сервиса по ее единоличному усмотрению.</p>

      <p>6.4. Получение Пользователем Поощрений не гарантировано.</p>

      <p>6.5. Информация о перечне, количестве Поощрений и условиях их получения доводится до Пользователя посредством
        интерфейсов Сервиса, в том числе размещается в профиле Пользователя на Сайте Сервиса на соответствующих
        страницах/ в разделах Сайта Сервиса.</p>

      <p>6.6. Информация об исчерпании Поощрений и прекращении их выдачи (предоставления) доводится до сведения
        Пользователей в интерфейсах Сервиса/ на Сайте Сервиса.</p>

      <p>6.7. Администрация Сервиса не несет ответственности за качество товаров и/или услуг партнеров,
        предоставляющих Поощрения, а также за полноту и достоверность предоставленной партнером информации о
        Поощрениях и соответствие такой информации фактическим условиям получения товаров и услуг партнера. Информация
        о порядке, сроках и условиях получения и использования такого Поощрения, предоставленного третьим лицом
        (партнером), предоставляется Пользователю в интерфейсе Сервиса/ на Сайте Сервиса, в том числе в виде ссылки на
        Интернет-ресурс партнера.</p>

      <p>6.8. В случае, если Поощрение реализовано в виде функциональности Сервиса (игровых очков, фрагментов пазла и
        проч.), такое Поощрение может быть использовано Пользователем исключительно в рамках Сервиса в соответствии с
        его логикой и сценарием.</p>

      <p>6.9. Поощрения могут быть использованы (активированы) в течение 2 (двух) месяцев со дня их предоставления
        Участнику Администрацией Сервиса или партнером, если иной срок не указан в правилах использования Поощрений,
        размещенных партнером и доведенных до сведения Пользователя в соответствии с п. 6.7. Соглашения. В случае,
        если Поощрения не использованы (не активированы) в течение указанного срока, Пользователь утрачивает право
        воспользоваться ими, Поощрения аннулируются. Возмещение Участнику стоимости Поощрений и/или предоставление
        какой-либо компенсации не производится.</p>

      <p class="legal-text-title">7. Отключение Сервиса</p>
      <p>7.1. Пользователь вправе отказаться от использования Сервиса, для этого Пользователю необходимо отправить
        SMS-сообщение со словом «Стоп» на номер 9303.</p>

      <p class="legal-text-title">8. Интеллектуальная собственность</p>
      <p>8.1. Доступ к Сервису и интеллектуальной собственности, размещенной в нем, для Пользователей ограничен
        пределами Российской Федерации.Пользователь гарантирует, что находится на территории Российской Федерации и не
        осуществляет каких-либо действий, направленных на обход технических средств защиты, контролирующих доступ к
        результатам интеллектуальной собственности, размещенной в Сервисе.</p>

      <p>8.2. Все используемые и размещенные в Сервисе результаты интеллектуальной деятельности, а также сам Сервис,
        являются интеллектуальной собственностью их законных правообладателей и охраняются законодательством об
        интеллектуальной собственности Российской Федерации, а также соответствующими международными правовыми
        конвенциями. Любое использование размещенных в рамках Сервиса результатов интеллектуальной деятельности (в том
        числе программ для ЭВМ и баз данных, а также элементов оформления Сервиса, символики, текстов, графических
        изображений, иллюстраций, фото, видео, программ, музыки и других объектов) без разрешения Администрации
        Сервиса или законного правообладателя является незаконным и может послужить причиной для судебного
        разбирательства и привлечения нарушителей к гражданско-правовой, административной и уголовной ответственности
        в соответствии с законодательством Российской Федерации.</p>

      <p>8.3. Любое использование Сервиса, кроме разрешенного в Соглашении, без предварительного письменного
        разрешения Администрации категорически запрещено.</p>

      <p>8.4. Все материалы Сервиса предназначены исключительно для личного некоммерческого использования в рамках
        объявленных технических возможностей, предоставляемых Сервисом. Любое копирование, воспроизведение,
        переработка, распространение, доведение до всеобщего сведения либо иное использование материалов Сервиса вне
        рамок возможностей, предоставляемых Сервисом, а также любое их использование в коммерческих целях запрещается.
        Запрещается: вскрывать технологию, эмулировать, декомпилировать, дизассемблировать, дешифровать и производить
        иные аналогичные действия с Сервисом и его программными компонентами; создавать программные продукты и/или
        сервисы с использованием Сервиса без получения предварительного разрешения Администрации Сервиса.</p>

      <p class="legal-text-title">9. Ограничения и ответственность</p>
      <p>9.1. Администрация Сервиса оставляет за собой право модифицировать Сервис (с письменного согласования
        правообладателя Сервиса). Администрация Сервиса не несет ответственности за причинение вреда, ущерба, потерю
        информации или за причинение любых других убытков любым лицам, которые возникли при пользовании Сервисом, в
        том числе с использованием мобильных средств связи и иных средств телекоммуникаций. Администрацией Сервиса
        осуществляется предоставление Сервиса исключительно для его использования Пользователем в личных
        некоммерческих целях, не связанных с осуществление предпринимательской или иной деятельности, направленной на
        извлечение прибыли.</p>

      <p>9.2. Обращения, предложения и претензии Пользователя в связи с использованием Сервиса, возможными нарушениями
        прав и интересов третьих лиц при его использовании, а также запросы уполномоченных законодательством
        Российской Федерации лиц могут быть направлены на электронный адрес support@via-mobi.ru.</p>

      <p class="legal-text-title">10. Персональные данные</p>
      <p>10.1. Принимая Соглашение, осуществляя подключение подписки на Сервис или используя Сервис, в том числе
        проходя регистрацию/ авторизацию в Сервисе, Пользователь свободно, своей волей и в своих интересах дает
        добровольное, осознанное согласие на следующие способы обработки Персональной информации: запись,
        систематизация, хранение, уточнение (обновление, изменение), извлечение, использование, передача
        (предоставление, доступ) третьим лицам, обезличивание, блокирование, удаление, уничтожение в целях и в
        порядке, установленных Соглашением, с использованием средств автоматизации или без использования таких средств
        по усмотрению Администрации Сервиса.</p>

      <p>10.2. Под Персональной информацией в Соглашении понимается:</p>
      <p>- информация, которую Пользователь предоставляет о себе самостоятельно при подключении и использовании
        Сервиса, при регистрации или авторизации в Сервисе, в процессе использования Сервиса, в том числе персональные
        данные Пользователя, включая номер телефона, личную фотографию, фамилию/ имя/ отчество или псевдоним
        Пользователя (если возможность предоставления таких данных функционально реализована в Сервисе);</p>
      <p>- технические данные, которые передаются в автоматическом режиме в зависимости от настроек программного
        обеспечения на мобильном устройстве Пользователя, в том числе файлы cookie;</p>
      <p>- данные об использовании Сервиса, в том числе о его подключении/ отключении/ приостановлении предоставления
        доступа к Сервису, об активности пользователя в Сервисе, о пользовательском опыте (результатах) Пользователя
        при использовании функционала Сервиса, приобретении внутрисервисных ценностей, количестве начисленных очков
        средствами Сервиса и проч.; тексты SMS-сообщений, ussd-команды, другие сообщения технического характера,
        направляемые Пользователем или Администрацией Сервиса при использовании Пользователем Сервиса;</p>
      <p>- данные, собираемые и передаваемые метрическим сервисом Яндекс.Метрика, осуществляющим сбор и обработку
        данных в соответствии с политикой конфиденциальности, размещенной в сети Интернет по адресу:
        https://yandex.ru/legal/confidential/. Указанные данные могут включать данные об устройстве Пользователя
        (модель, производитель, объем свободной памяти), место его положения (страна, город), данные операционной
        системы (тип, версия, разрешение экрана) и другие; данные запроса (время, источник перехода, IP-адрес); иные
        обезличенные технические данные об устройстве Пользователя или о Пользователя; данные о действиях Пользователя
        в Сервисе, в том числе получаемые с помощью передачи файлов cookie.</p>

      <p>10.3. Администрация Сервиса устанавливает требования к составу Персональной информации Пользователя, которая
        должна обязательно предоставляться для подключения подписки на Сервис и/или использования Сервиса. Если в
        отношении определенной информации в интерфейсе Сервиса не указано на обязательность предоставления, такая
        информация предоставляется (раскрывается) Пользователем по его собственному усмотрению.</p>

      <p>10.4. В зависимости от технических настроек на мобильном устройстве Пользователя, данные об абонентском
        номере телефона Пользователя могут в автоматическом режиме передаваться Администрации Сервиса при подключении
        Сервиса и дальнейшем использовании Сервиса (в процессе авторизации в Сервисе). В этом случае Пользователь не
        указывает в Интерфейсе Сервиса/Сайте Сервиса или в Мобильном приложении абонентский номер телефона, но дает
        согласие на обработку данных об абонентском номере телефона в соответствии с настоящим разделом
        Соглашения.</p>

      <p>10.5. Размещая в Сервисе, в том числе на Сайте Сервиса или в Мобильном приложении, Персональную информацию, в
        том числе свои персональные данные, Пользователь осознает и соглашается с тем, что указанная информация может
        быть доступна другим пользователям сети Интернет с учетом особенностей интерфейса и функционала Сервиса.
        Пользователь осуществляет использование функционала Сервиса, в том числе путем размещения в нем персональных
        данных (включая фотографии, данные о фамилии, имени, отчестве Пользователя или его псевдониме) по своему
        усмотрению и на свой риск. Администрация Сервиса принимает технические и организационные меры по обеспечению
        функционирования соответствующего функционала Сервиса.</p>

      <p>10.6. Администрация Сервиса не инициирует и не влияет на такой выбор Пользователя, а также не имеет цели
        получить у Пользователя разрешение на распространение его персональных данных. Обработка персональных данных,
        раскрытых неопределенному кругу лиц самим Пользователем (субъектом персональных данных), осуществляется
        Администрацией Сервиса на основании и в соответствии с условиями Соглашения.</p>

      <p>10.7. Администрация Сервиса не несет ответственности за использование и разглашение персональных данных
        Пользователя другими Пользователями Сервиса или иными третьими лицами, получившими доступ к таким данным
        посредством Сервиса, и за последствия использования информации, которая, в силу назначения и особенностей
        реализации Сервиса, доступна любому пользователю сети Интернет. Администрация Сервиса рекомендует
        Пользователям ответственно подходить к решению вопроса об объеме и содержании контента и персональных данных,
        размещаемых в Сервисе.</p>

      <p>10.8. Администрация Сервиса и третье лицо, указанное в п. 10.9. Соглашения, осуществляют обработку
        Персональной информации Пользователя в целях заключения и исполнения Соглашения, других договоров по
        инициативе Пользователя; предоставления Сервиса; проведения Стимулирующих Мероприятий и вручения призов
        (выигрышей); разработки, улучшения Сервиса; технической и информационной поддержки Пользователей с целью
        бесперебойного и качественного функционирования Сервиса, а также информирования Пользователя о событиях,
        связанных с подключением и использованием Сервиса; идентификация Пользователя при подключении и использовании
        Сервиса; обеспечение безопасности и конфиденциальности Персональной информации. Администрация Сервиса, вправе
        осуществлять обработку персональных данных Пользователя, также в целях исполнения налоговых обязанностей по
        предоставлению в налоговые органы сведений о доходе в виде призов, полученном Пользователями, признанными
        победителями Акций, а также иных обязанностей, установленных Налоговым кодексом РФ в связи с предоставлением
        призов Пользователям.</p>

      <p>10.9. Пользователь даёт своё согласие на передачу Администрацией Сервиса Персональной информации
        Пользователя, указанной в п. 10.2. Соглашения, третьему лицу - ООО «ВиаМоби» (место нахождения 129626, г.
        Москва, Проспект Мира, д. 102, корп. 1, офис 706; ИНН 7725780914; ОГРН 1137746059593) и ее обработку указанным
        третьим лицом, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение),
        извлечение, использование, обезличивание, блокирование, удаление, уничтожение указанных данных, в целях,
        указанных в п. 10.8. Соглашения.</p>

      <p>10.10. Пользователь не вправе предоставлять Администрации Сервиса персональные данные третьих лиц без их
        согласия в соответствии с Федеральным законом Российской Федерации «О персональных данных».</p>

      <p>10.11. Соглашение регулируется законодательством Российской Федерации, в том числе в части обработки
        персональных данных, а также политикой обработки персональных данных в ПАО «ВымпелКом», которая размещена на
        <a href="https://beeline.ru/customers/legal/service-and-sale/.">https://beeline.ru/customers/legal/service-and-sale/</a>.
      </p>

      <p>10.12. Администрация Сервиса и лицо, указанное в п.10.9. Соглашения, вправе осуществлять обработку
        персональной информации Пользователя на условиях Соглашения в течение всего срока использования Пользователем
        Сервиса и после прекращения такого использования - в течение срока исковой давности по обязательствам,
        возникающим из Соглашения и заключаемых на его основе договоров.</p>

      <p class="legal-text-title">11. Заключительные положения</p>
      <p>11.1. Соглашение регулируется законодательством Российской Федерации, в том числе в части обработки
        персональных данных. Вопросы, не урегулированные Соглашением, разрешаются в соответствии с законодательством
        Российской Федерации.</p>

      <p>11.2. Все споры, возникающие в связи с использованием Сервиса, разрешаются Пользователем и Администрацией
        Сервиса в досудебном порядке путем переговоров.</p>
      <p>Если какое-либо условие Соглашения будет признано судом или любым другим компетентным органом
        недействительным, незаконным или не имеющим силу, то это не повлияет на действительность, законность и
        действие остальных условий Соглашения.</p>

      <p>11.3. Соглашение может быть в одностороннем порядке изменено Администрацией Сервиса путем размещения новой
        редакции Соглашения на сайте beeline.ru или krossvord.beeline.ru. Новая редакция Соглашения вступает в силу и
        применяется к отношениям Сторон с момента ее размещения по указанной ссылке.</p>

      <p class="legal-text-title">Приложение № 1 к Пользовательскому соглашению
        сервиса «Мелоквиз»</p>

      <p>Таблица 1</p>
      <table>
        <tr>
          <th colspan="3">Перечень поощрений, разыгрываемых ежемесячно при проведении стимулирующих мероприятий
            Администрацией Сервиса
          </th>
        </tr>
        <tr>
          <th>Название поощрения</th>
          <th>Номинал</th>
          <th>Число призов</th>
        </tr>
        <tr>
          <td>Скидка до 2 500 на покупки в интернет-магазине билайн*</td>
          <td>2 500</td>
          <td>10</td>
        </tr>
        <tr>
          <td>Скидка до 5 000 на покупки в интернет-магазине билайн*</td>
          <td>5 000</td>
          <td>8</td>
        </tr>
        <tr>
          <td>Скидка до 10 000 на покупки в интернет-магазине билайн*</td>
          <td>10 000</td>
          <td>3</td>
        </tr>
        <tr>
          <td>Скидка до 25 000 на покупки в интернет-магазине билайн*</td>
          <td>25 000</td>
          <td>2</td>
        </tr>
        <tr>
          <td>Скидка до 50 000 на покупки в интернет-магазине билайн*</td>
          <td>50 000</td>
          <td>1</td>
        </tr>
        <tr>
          <td>Скидка до 100 000 на покупки в интернет-магазине билайн*</td>
          <td>100 000</td>
          <td>1</td>
        </tr>
        <tr>
          <td>500 Бонусные рубли**</td>
          <td>500</td>
          <td>410</td>
        </tr>
        <tr>
          <td>1 000 Бонусные рубли**</td>
          <td>1 000</td>
          <td>50</td>
        </tr>
        <tr>
          <td>2 500 Бонусные рубли**</td>
          <td>2 500</td>
          <td>10</td>
        </tr>
        <tr>
          <td>5 000 Бонусные рубли**</td>
          <td>5 000</td>
          <td>5</td>
        </tr>
      </table>

      <p>*Промокод Интернет-магазина – промокод на скидку (до 100%) в интернет-магазине билайн определенного номинала.
        Может быть использован один раз. Действует до конца месяца, следующего за месяцем выдачи Промокода
        Интернет-магазина.</p>

      <p>**Бонусные рубли – это Приз в виде баллов, которые начисляются Участнику на бонусный баланс абонентского
        номера и расходуются на оплату абонентской платы по тарифному плану Оператора с предоплатной системой
        расчетов. Бонусные рубли могут быть использованы в течение 2 (Двух) месяцев с момента начисления. Для того,
        чтобы воспользоваться Бонусными рублями, необходимо быть абонентом ПАО «ВымпелКом», в том числе при
        перенесении абонентского номера телефона Участника, который использовался Участником для участия в Сервисе, в
        сеть связи Оператора на основании поданного заявления на портацию и заключенного с Оператором договора на
        оказание услуг подвижной радиотелефонной связи билайн.
        Бонусные рубли начисляются Участникам в срок до 10 дней. Бонусные рубли не являются денежными средствами,
        средствами платежа или каким-либо видом валюты или ценной бумаги, и не могут быть использованы для погашения
        задолженности по договору, заключенному с ПАО «ВымпелКом» и выданы при расторжении указанного договора. Вид и
        объем услуг связи, оплата которых может быть произведена Участником за счет Бонусных рублей, определяется
        Оператором. Бонусные рубли зачисляются на Бонусный баланс и расходуются автоматически в первую очередь в
        течение срока действия перед средствами с основного баланса на оплату абонентской платы по тарифному плану.
        При нахождении в отрицательном балансе использование Бонусных рублей невозможно. Бонусные рубли не расходуются
        в том числе: </p>
      <p>• на оплату услуг связи, оказанных в международном роуминге и в поездках по России в сетях других
        операторов;</p>
      <p>• на оплату контентных услуг /сервисов;</p>
      <p>• на оплату услуг третьих лиц с использованием мобильного платежа;</p>
      <p>• на исходящие вызовы на платные номера голосовых информационно-справочных служб;</p>
      <p>• на плату за подключение дополнительных услуг и на абонентскую плату за дополнительные услуги;</p>
      <p>• на плату за оборудование (роутеры/приставки/видеокамеры и т.п.).</p>

      <p>Проверка баланса Бонусных рублей возможна по номеру: *105#. Информация о балансе Бонусных рублей также
        отображается в личном кабинете абонента билайн и Мобильном приложении.</p>
      <p>Информацию о возможностях использования Бонусных рублей можно получить по короткому номеру 0559.</p>
      <p>При исчерпании Бонусных рублей, абонентская плата по тарифу будет списываться с основного баланса.</p>

      <p>Таблица 2</p>
      <table>
        <tr>
          <th colspan="3">Перечень поощрений, при сборе пазла</th>
        </tr>
        <tr>
          <th>Название поощрения</th>
          <th>Скидка</th>
          <th>Число призов</th>
        </tr>
        <tr>
          <td>Скидка до 100% на покупку фирменных носков от билайна</td>
          <td>до 100%</td>
          <td>20</td>
        </tr>
        <tr>
          <td>1000 кристаллов*</td>
          <td>1000</td>
          <td>неограничено</td>
        </tr>
      </table>

      <p>*Кристаллы - внутрисервисные ценности</p>
    </div>
  </div>
</template>

<script>

import {RouterLink, RouterView} from 'vue-router'

export default {
  name: 'AppHeader',
  components: {
    RouterLink,
    RouterView
  },
};
</script>
