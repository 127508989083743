<template>

  <div class="supergame-grid">
    <div class="supergame-grid-item-container" v-for="item in categoriesList" :key="item.id">
      <div class="supergame-grid-item-quests">{{item.daily_answers_count}}/{{item.daily_questions_count}}</div>
      <div class="supergame-grid-item" @click="game(item)" :class="{'item-disabled': item.questions_count == 0, 'has-daily': item.is_daily_quest, 'completed' : item.daily_answers_count === item.daily_questions_count}">
      <img :src="getImageUrl(item.icon_url)" class="main-grid-item-img main-grid-item-img--quest" alt="" />
        <div class="main-grid-item-caption">
          <div class="main-grid-item-title">{{ item.title }}</div>
          <div class="main-grid-item-progress">{{ item.answers_count }}/{{ item.questions_count }}</div>
        </div>
      </div>
    </div>
  </div>

</template>


<script>
import { mapState, mapActions } from 'pinia';
import { useUserStore } from '@/store/user';

export default {
  name: 'SuperGameGrid',
  props: {
    puzzle: {
      type: Object,
      default: {}
    },
    info: {
      type: Object,
      default: {}
    },

  },
  components: {

  },
  data() {
    return {
      showModal: false,
      hint: false,
      progressValue: 0,
      categories: []
    };
  },
  computed: {
    ...mapState(useUserStore, ['userInfo']),
    categoriesList () {
      let arr = [];
      if (this.userInfo.categories){
        this.userInfo.categories.forEach((el) => {
          if (el.is_daily_quest){
            arr.push(el)
          }
        })
      }


      return arr;
    },
  },
  created() {

  },
  methods: {
    ...mapActions(useUserStore, ['startGame', 'setCurrentCategory', 'showNoLivePopUp', 'itsQuestToggle']),
    getImageUrl(url) {
      return new URL(url, import.meta.url).href
    },
    game(item){
      if (this.userInfo.lives > 0) {
        this.setCurrentCategory(item);
        this.itsQuestToggle(true);
        this.$router.push({name: `Game`})
      }  else {
        this.showNoLivePopUp();
      }
    }
  }
};
</script>
