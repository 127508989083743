<template>
  <transition name="fade">
  <div class="answer" @click="pickCell()" :class="{clicked: clicked, fail: item.fail, win: item.win, disabled: item.disabled}">
      {{item.title}}
  </div>
  </transition>

</template>


<script>
import { mapState, mapActions } from 'pinia';

export default {
  name: 'answer',
  props: {
    item: {
      type: Object,
      default: {}
    },
    answerChoosed: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['check'],
  data() {
    return {
      showModal: false,
      hint: false,
      progressValue: 0,
      clicked: false
    };
  },
  methods: {
    pickCell() {
      if (this.answerChoosed) {
        return
      }
      this.clicked = true;
      this.$emit('check', this.item.id)
    }
  }
};
</script>
