<template>
  <VueFinalModal
      class="modal-wrapper"
      content-class="modal-container"
      overlay-class="modal-overlay"
      @update:model-value="val => $emit('close')"
      :click-to-close="false"
      z-index-base="1000"
  >
    <div class="modal-close" @click="$emit('close')" v-if="closeBtn">
      <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 2.48503L2.13908 0.345947L7 5.20686L11.8609 0.345947L14 2.48503L9.13908 7.34595L14 12.2069L11.8609 14.3459L7 9.48387L2.13908 14.3459L0 12.2069L4.86208 7.34595L0 2.48503Z" fill="white"/>
      </svg>
    </div>
    <div class="modal-content-wrapper">
      <div v-if="title" class="modal-title">
        {{ title }}
      </div>
      <slot name="customHeader"></slot>
      <div class="modal-content">
        <slot />
      </div>
      <slot name="customFooter"></slot>
      <slot name="customButtons"></slot>
      <div class="modal-buttons" v-if="btnText">
        <div v-if="textBeforebtn" v-html="textBeforebtn"></div>
        <button class="btn btn-primary" @click="$emit('confirm')">
          {{ btnText }}
        </button>
      </div>
    </div>

  </VueFinalModal>
</template>

<script>
import { RouterLink, RouterView } from 'vue-router'
import { VueFinalModal } from 'vue-final-modal'
import { useMainStore } from '@/store';
import { mapState, mapActions } from 'pinia';

export default {
  name: 'Modal',
  props: {
    clickToclose: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
    btnText: {
      type: String,
      default: ''
    },
    star: {
      type: Boolean,
      default: false
    },
    closeBtn: {
      type: Boolean,
      default: true
    },
    textBeforebtn: {
      type: String,
      default: '',
    }
  },
  emits: ['close','confirm'],
  components: {
    VueFinalModal
  },
  methods: {

  }
};
</script>
