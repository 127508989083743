<template>
  <teleport to="body">
    <div class="trialBlock" @click="goToSubscribe()" :class="{trialEnded: itsTrialEnded}">
      <div v-if="!itsTrialEnded">
        Вы играете в триал версию. Она закончится через:
        <vue-countdown class="trialBlock__count" ref="countdown" :time="userInfo.trial_lifetime * 1000" v-slot="{ hours, minutes, seconds }" :transform="transformSlotProps"  @end="onCountDownEnd()">
          <span v-if="hours > 0">{{hours}}:{{ minutes }}</span>
          <span v-else>{{minutes}}:{{ seconds }}</span>
        </vue-countdown>
      </div>
      <div v-else>
        Оформите подписку, чтобы получить доступ ко всем функциям сервиса
      </div>


    </div>
</teleport>
</template>

<script>
  import { RouterLink, RouterView } from 'vue-router'
  import { useUserStore } from '@/store/user';
  import { mapState, mapActions } from 'pinia';
  import VueCountdown from '@chenfengyuan/vue-countdown';
  import { ref, onMounted } from 'vue'

  export default {
    name: 'Nav',
    components: {
      RouterLink,
      RouterView,
      VueCountdown,
    },
    data(){
      return{
        counting: true
      }
    },
    computed: {
      ...mapState(useUserStore, ['userInfo', 'itsTrialEnded', 'itsTrial']),
    },
    methods:{
      ...mapActions(useUserStore, ['getUserInfo']),
      transformSlotProps(props) {
        const formattedProps = {};

        Object.entries(props).forEach(([key, value]) => {
          formattedProps[key] = value < 10 ? `0${value}` : String(value);
        });

        return formattedProps;
      },
      async onCountDownEnd(){
        const result = await this.getUserInfo();
      },
      goToSubscribe(){
        if (this.itsTrialEnded)
          window.location.href = '/backend/subscribe_web';
      }
    }
  };
</script>
