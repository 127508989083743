import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { useAuthStore } from '@/store/auth';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/:pathMatch(.*)',
      name: 'error404',
      component: () => import(/* webpackChunkName: "error" */ '@/views/ErrorView.vue'),
      meta: { errorPage: true },
    },
    {
      path: '/',
      name: 'home',
      component: HomeView,
      meta: {
        transition: 'fade',
        title: 'MeloQuiz',
        metaTags: [
          {
            name: 'description',
            content: 'MeloQuiz'
          },
          {
            property: 'og:description',
            content: 'MeloQuiz'
          }
        ]
      },
    },
    {
      path: '/shop',
      name: 'Shop',
      component: () => import('@/views/ShopView.vue'),
      meta: { transition: 'fade', shopPage: true},
    },
    {
      path: '/presents',
      name: 'Presents',
      component: () => import('@/views/PresentsView.vue'),
      meta: { transition: 'fade' , shopPage: true},
    },
    {
      path: '/gamerules',
      name: 'GameRules',
      component: () => import('@/views/GameRulesView.vue'),
      meta: { transition: 'fade' , shopPage: true},
    },
    {
      path: '/game',
      name: 'Game',
      props: true,
      component: () => import('@/views/GameView.vue'),
      meta: { transition: 'fade', hideMenu: true, tipsEnable: true, gamePage: true},
    },
    {
      path: '/supergame',
      name: 'Supergame',
      component: () => import('@/views/SuperGameView.vue'),
      meta: { transition: 'fade', hideMenu: true, superGameHub: true},
    },
    {
      path: '/supergame-start',
      name: 'SupergameQuestions',
      component: () => import('@/views/SuperGameQuestionView.vue'),
      meta: { transition: 'fade', hideMenu: true, superGame: true, },
    },
    {
      path: '/rules',
      name: 'Rules',
      component: () => import('@/views/RulesView.vue'),
      meta: { transition: 'fade', hideMenu: true,  },
    },
    {
      path: '/legal',
      name: 'Legal',
      component: () => import('@/views/pageView.vue'),
      meta: { transition: 'fade', hideMenu: true, hideMobileHeader: true, hideHeader: false},
    },
    {
      path: '/test',
      name: 'Test',
      component: () => import('@/views/SuperGameTest.vue'),
      meta: { transition: 'fade', hideMenu: true, superGame: true, },
    },
  ]
})

export default router
