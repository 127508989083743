<template>
  <div :class="['shop-page-shopItem', {'shop-page-shopItem--disable': disable}]" @click="buyItem">
    <div :class="['shopItem__img', `shopItem__img--${currentType}_${item.count}`]">
    </div>
    <p :class="['shopItem__count',`shopItem__count--${currentType}`]">{{item.count}}</p>
    <button :class="['shopItem__btn btn btn-primary', {'shopItem__btn--coins' : currentType === 'lives'}]">
      <p class="shopItem__price">{{priceSignature}}</p>
    </button>
  </div>
</template>

<script>

export default {
  name: 'ShopItem',
  props: {
    item: {
      type: Object,
      default: {},
    },
    currentType: {
      type: String,
      default:'coins',
    },
    disable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
      priceSignature () {
        return this.currentType === 'coins' ? `${this.item.price} руб.` : this.item.price;
      },
    },
  data() {
    return {
    }
  },
  methods: {
    buyItem() {
      if (this.disable) {
        return 
      }
      this.$emit('buyItem', this.item);
    },
  }
};
</script>
