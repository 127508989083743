<template>
  <div class="error-page-container">
    <div class="error-page-top">
      <div class="error-page-title">Ошибка</div>
      <div class="error-page-text">Ой... Что-то пошло не&nbsp;так.<br>Не&nbsp;переживайте, мы&nbsp;обязательно всё починим!</div>
    </div>
  </div>
</template>

<script>

import { RouterLink, RouterView } from 'vue-router'

export default {
  name: 'AppHeader',
  components: {
    RouterLink,
    RouterView
  },
};
</script>
