<template>
  <div class="page-content supergame-page-content page-content--quest">
    <div class="game-header game-header--quest">
      <router-link to="/" class="go-back">
        <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.26033 6.95063L10.3824 2.69325L8.12965 0.375519L0.141113 8.59441L8.12965 16.8133L10.3824 14.4956L6.26033 10.2382H19.4818V6.95063H6.26033Z" fill="#8C36BD"/>
        </svg>
      </router-link>
      <div class="game-header-text">
        <div class="game-title game-title--quest">
          Пройдите ежедневные квесты для СУПЕРИГРЫ!<br>
          <!-- <span class="hidden-xs">Квесты обновляются в 00:00 по московскому времени</span> -->
        </div>
      </div>
    </div>
    <div class="super-game-categories">
      <div class="super-game-categories-title">Доступные квесты:</div>
      <super-game-categories></super-game-categories>
    </div>
    <div v-if="userInfo.supergame.availability!=2" class="super-info-bottom"><span>Квесты обновляются в 00:00 по&nbsp;московскому времени</span></div>
    <div v-else class="super-info-bottom super-info-bottom--timerBlock">
      <p class="super-info-bottom--timer"><vue-countdown :time="expTime()" v-slot="{ hours, minutes, seconds }" :transform="transformSlotProps"  @end="onCountDownEnd()">
              {{ hours }}:{{ minutes }}:{{ seconds }}
            </vue-countdown></p>
      <span>Таймер запущен!<br> У вас есть час чтобы пройти  суперигру.<br>Готовы начать?</span>
    </div>

    <div class="super-game-start" v-if="userInfo.supergame">
      <button @click="superGamePopup" class="super-game-start-btn btn btn-large btn--56pxMobile btn-yellow" :class="{'disabled': userInfo.supergame.availability!=2}">Запустить СУПЕРИГРУ!</button>
    </div>

    <Modal
        v-model="superGameRules"
        @close="rulesModalClose"
        @confirm="rulesModalConfirm"
        :title="'Правила'"
        :btnText="'Понятно'"
        class="superGameRules-popup"
    >

      <div class="super-game-rules-text">
        <ul>
          <li>Всего 15 вопросов &ndash; ответите на все и победите!</li>
          <li>За каждые 5 правильных ответов вы получаете гарантированые награды!</li>
          <li>На всю игру доступны 3 подсказки</li>
          <li>Начать суперигру можно в течение 1 часа</li>
          <li>На каждый вопрос даётся ограниченное количество времени</li>
        </ul>
      </div>
      <div class="super-game-rules-rewards">
        <div class="super-game-rewards-title">Награды за правильные ответы</div>
        <div class="super-game-rewards-list">
          <div class="super-game-rewards-row">
            <div class="super-game-rewards-progress width-30">
              <div class="rewards-progress-value">
                <span>5</span>
              </div>
            </div>
            <div class="ndash">&ndash;</div>
            <div class="super-game-reward"><span class="icon-coin"></span>100</div>
          </div>

          <div class="super-game-rewards-row">
            <div class="super-game-rewards-progress width-60">
              <div class="rewards-progress-value">
                <span>10</span>
              </div>
            </div>
            <div class="ndash">&ndash;</div>
            <div class="super-game-reward"><span class="icon-coin"></span>350</div>
          </div>

          <div class="super-game-rewards-row">
            <div class="super-game-rewards-progress width-100">
              <div class="rewards-progress-value">
                <span>15</span>
              </div>
            </div>
            <div class="ndash">&ndash;</div>
            <div class="super-game-reward"><span class="icon-coin"></span>650&nbsp;&nbsp;+&nbsp;<span class="icon-lives"></span>3&nbsp;+&nbsp;<span class="icon-promo"></span></div>
          </div>


        </div>
      </div>


    </Modal>

  </div>
</template>

<script>

import {RouterLink, RouterView} from 'vue-router'
import { mapState, mapActions } from 'pinia';
import { useUserStore } from '@/store/user';
import superGameCategories from "@/components/supergame/categories.vue";
import Modal from '@/components/modals/Modal.vue';
import VueCountdown from '@chenfengyuan/vue-countdown';

export default {
  name: 'AppHeader',
  components: {
    RouterLink,
    RouterView,
    superGameCategories,
    Modal,
    VueCountdown
  },
  data() {
    return {
      superGameRules: false,
    };
  },
  computed: {
    ...mapState(useUserStore, ['userInfo']),
  },
  methods: {
    ...mapActions(useUserStore, ['startGame', 'setCurrentCategory','resetCurrent']),
    expTime(){
      if (this.userInfo.supergame.availability===2 && this.userInfo.supergame.expires_at > 0){
        this.expTimeVal = true;
        return this.userInfo.supergame.expires_at * 1000
      }
      else if (this.userInfo.supergame.upcoming_game && this.userInfo.supergame.upcoming_game > 0){
        this.expTimeVal = true;
        return this.userInfo.supergame.upcoming_game * 1000
      }

    },
    transformSlotProps(props) {
      const formattedProps = {};

      Object.entries(props).forEach(([key, value]) => {
        formattedProps[key] = value < 10 ? `0${value}` : String(value);
      });

      return formattedProps;
    },
    onCountDownEnd(){
      // console.log('end')
    },
    superGamePopup () {
      this.superGameRules = true;
    },
    superGameStart () {
      this.resetCurrent();
      this.$router.push({path: `/supergame-start` })
    },
    rulesModalClose(){
      this.superGameRules = false;
    },
    rulesModalConfirm(){
      this.superGameStart();
    },
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (vm && vm.userInfo.supergame.availability!=1 && vm.userInfo.supergame.availability!=3 && vm.userInfo.supergame.availability!=2){
        vm.$router.push({path: `/` })
      }
    })
  }
};
</script>
