<template>
    <div class="presentCard">
        <div class="presentCard__top">
            <img class="presentCard__img"  :src="getImageUrl(item.offer.partner.logoUrl)"  :alt="item.offer.partner.title">
            <p class="presentCard__title">{{item.offer.title}}</p>
        </div>
        <div class="presentCard__bottom">
          <p class="presentCard__title">{{item.offer.title}}</p>
            <Code :code="item.value" class="presentCard__code" toastSuccess="Скопировано в буфер обмена"></Code>
            <a  target="_blank" :href=item.offer.url class="btn btn-primary presentCard__btn">Активировать</a>
        </div>
        <button class="presentCard__infoButton" @click="$emit('clickShowModal', item)">
            <!-- <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.02719 10.8454C3.67611 10.8438 3.33426 10.958 3.05435 11.1703C2.79251 11.4684 2.65681 11.8569 2.67602 12.2535C2.67378 12.4246 2.70615 12.5944 2.77117 12.7526C2.83619 12.9109 2.93252 13.0543 3.05435 13.1742C3.17837 13.3092 3.32868 13.4172 3.496 13.4918C3.66332 13.5663 3.8441 13.6056 4.02719 13.6075C4.21158 13.6143 4.39509 13.5788 4.56369 13.5037C4.7323 13.4286 4.88155 13.3159 5.00004 13.1742C5.13665 13.0633 5.24649 12.9228 5.32136 12.7634C5.39623 12.604 5.43419 12.4297 5.43242 12.2535C5.44249 12.0524 5.40906 11.8515 5.33444 11.6645C5.25981 11.4776 5.14574 11.309 5.00004 11.1703C4.72715 10.945 4.38046 10.8292 4.02719 10.8454ZM7.21597 6.45857C7.52571 6.10117 7.78072 5.69962 7.97263 5.26709C8.20303 4.83469 8.31492 4.34882 8.29691 3.85898C8.316 3.36476 8.21243 2.87353 7.99549 2.42928C7.77854 1.98504 7.45499 1.60168 7.05383 1.31354C6.27248 0.689878 5.29594 0.363695 4.29743 0.39285C3.38378 0.37814 2.48454 0.622184 1.70317 1.09691C0.950308 1.55985 0.378158 2.26695 0.0817566 3.10076L1.91936 4.07561C2.06934 3.57942 2.37201 3.14344 2.78411 2.82997C3.21491 2.53949 3.7242 2.38828 4.24338 2.3967C4.74946 2.35926 5.25108 2.51392 5.64861 2.82997C5.8008 2.96637 5.92273 3.13325 6.00651 3.31983C6.0903 3.50641 6.13409 3.70853 6.13503 3.91313C6.13266 4.29064 6.03999 4.66208 5.86479 4.9963C5.6341 5.336 5.36173 5.64532 5.05409 5.91699C4.72981 6.20944 4.42714 6.49106 4.13529 6.78352C3.80541 7.11616 3.53161 7.50027 3.32458 7.92084C3.10377 8.33735 2.99219 8.80324 3.0003 9.2748H5.05409C5.04932 8.88042 5.18326 8.49695 5.43242 8.19163C5.66072 7.84873 5.91361 7.52292 6.18908 7.21678L7.21597 6.45857Z" fill="#8328CB"/>
            </svg> -->
        </button>
    </div>
</template>


  <script>
    import Code from '@/components/promo/codeBlock.vue'

    export default {

        name: 'PresentCard',
        props: {
            item: {
                type: Object,
                default: {
                    offer: {
                        title: '',
                        url: '',
                    },
                value: '',
                }
            }
        },
        components: {
            Code
        },
        data() {
            return {

            }
        },
        computed: {
        },
        created() {
        },
        methods: {
            getImageUrl(url) {
                return new URL(url, import.meta.url).href
            },
        }
    };
  </script>
