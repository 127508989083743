<template>
  <Modal
      v-model="showModal"
      @close="closePupUp"
      @confirm="btnAction"
      :title="'Поздравляем!'"
      :btnText="questContinue ? 'Продолжить' : 'Запустить!'"
      :class="['default-modal']"
  >

    <div class="default-modal-mainContent">
        <div class="default-modal-icon">
            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M33.2703 32.661C32.111 32.7789 30.9907 33.1389 29.9841 33.717C28.9775 34.2951 28.1076 35.0779 27.4328 36.0133C26.7074 36.9064 26.1773 37.9374 25.876 39.0418C25.5746 40.1461 25.5084 41.2999 25.6816 42.4306C25.7897 43.5641 26.1404 44.6622 26.7105 45.6527C27.2806 46.6432 28.0573 47.5036 28.9895 48.1774H29.0867C29.9718 48.8964 31.002 49.422 32.109 49.7192C33.216 50.0165 34.3747 50.0786 35.508 49.9014C36.6764 49.7678 37.8048 49.4017 38.825 48.8254C39.8453 48.2492 40.7359 47.4747 41.4427 46.5491C42.3335 45.369 42.9028 43.9842 43.0966 42.5264L50 5.36377L19.517 0L14.2013 30.2665C13.2942 29.5523 12.2469 29.0305 11.1251 28.7338C10.0034 28.4371 8.83134 28.372 7.68279 28.5425C6.52871 28.6788 5.41537 29.0467 4.41135 29.6232C3.40733 30.1998 2.53396 30.9729 1.84536 31.8948C1.1199 32.7878 0.589861 33.8189 0.288493 34.9232C-0.0128746 36.0276 -0.0789912 37.1814 0.0941969 38.3121C0.233787 39.4479 0.607879 40.5437 1.19347 41.532C1.77905 42.5202 2.56376 43.3802 3.49937 44.0589C4.4059 44.7741 5.45316 45.2965 6.57509 45.5933C7.69703 45.89 8.86923 45.9546 10.0178 45.7829C11.1703 45.6411 12.2817 45.271 13.285 44.6949C14.2882 44.1187 15.1626 43.3485 15.8552 42.4306C16.7984 41.2841 17.4638 39.9411 17.801 38.5036L22.6891 11.0447L43.3885 14.6544L39.8471 34.3851C38.9329 33.6632 37.8758 33.1371 36.7434 32.8402C35.6109 32.5433 34.428 32.4823 33.2703 32.661Z" fill="white"/>
            </svg>
        </div>
        <p class="default-modal-text"><span v-if="itsQuest">Квест пройден!</span> Вы&nbsp;собрали&nbsp;{{answersCount}}&nbsp;{{notesSign}}&nbsp;из&nbsp;{{questionsCount}}</p>
        <p class="default-modal-text default-modal-text--mediumDS" v-if="questContinue">Соберите оставшиеся ноты, чтобы запустить СУПЕРИГРУ</p>
        <p class="default-modal-text default-modal-text--mediumDS" v-else>СУПЕРИГРА готова к запуску!</p>
    </div>

  </Modal>
  </template>
  
  
  <script>
    import { useModal } from 'vue-final-modal'
    import Modal from '@/components/modals/Modal.vue'
    import VueCountdown from '@chenfengyuan/vue-countdown';


    export default {
        name: 'questCompleteModal.vue',
        components: {
        Modal,
        },
        props: {
            questCompleteModal: {
                type: Boolean,
                default: false,
            },
            answersCount: {
                type: Number,
                default: 0,
            },
            questionsCount: {
                type: Number,
                default: 0,
            },
            itsQuest: {
                type: Boolean,
                default: false,
            }
        },
        data () {
        return {
        }
        }, 
        computed: {
            questContinue() {
                if (this.answersCount < this.questionsCount) {
                    return true
                } else {
                    return false
                }
            },
            showModal () {
                return this.questCompleteModal;
            },
            notesSign() {
                if (this.answersCount < 21 && this.answersCount > 9) {
                    return 'нот'
                } else {
                    let num =  this.answersCount.toString()[0];   
                    if (num == 1) {
                        return 'нота';
                    }  
                    if (num > 1 && num < 5) {
                        return 'ноты'
                    }
                    if (num > 6) {
                        return 'нот'
                    }
                }
                return 'нот';
                }
        },
        methods: {  
            btnAction () {
                this.$router.push('/supergame');
            },
            closePupUp() {
                this.$emit('confirmClose', false);
            },
        },
    };
  </script>
  