<template>
  <div class="page-container">
    <div class="page-title page-content-title">Пользовательское соглашение сервиса «Мир кроссвордов»</div>
    <div class="page-inner content-layout">
      <div class="content-layout-top">
        <router-link class="go-back" to="/">{{ $t("nav.back") }}</router-link>
      </div>

      rules

    </div>
  </div>
</template>

<script>

import {RouterLink, RouterView} from 'vue-router'

export default {
  name: 'AppHeader',
  components: {
    RouterLink,
    RouterView
  },
};
</script>
