<template>
<div class="game-tips-wrap">

  <div class="game-tips">

    <div class="tip-item" v-for="item in tips" :class="{locked: item.locked || buyInProgress || gameFinish, noMoney: item.noMoney}" @click="tipActivate(item)">
      <div class="tip" :data-id="item.id">
        <div class="tip-price">{{item.price}}</div>
      </div>
    </div>

  </div>


  <Modal
      v-model="balanceModal"
      :title="'Не хватает монет'"
      @close="balanceModal = false"
  >
    <div class="buy-popup-text">
      Перейдите в магазин для покупки кристаллов
    </div>

    <template #customFooter>
      <div class="modal-buttons-large mt-0">
        <div class="btn btn-secondary" @click="balanceModal = false">Отмена</div>
        <div class="btn btn-primary" @click="goToStore">В магазин</div>
      </div>
    </template>

  </Modal>

</div>

</template>


<script>
import { useUserStore } from '@/store/user';
import { mapState, mapActions } from 'pinia';
import Modal from '@/components/modals/Modal.vue'
import VueCountdown from '@chenfengyuan/vue-countdown';

export default {
  name: 'difficulty',
  props: {
    tips: {
      type: Object,
      default: {}
    },
    loading: {
      type: Boolean,
      default: false
    },
    cancelBtn: {
      type: Boolean,
      default: false
    },
    checkBtn: {
      type: Boolean,
      default: false
    },
    checkbtnActive: {
      type: Boolean,
      default: false
    },
    buyInProgress: {
      type: Boolean, 
      default: false,
    },
    gameFinish: { 
      type: Boolean, 
      default: false,
    }
  },
  emits: ['buy', 'countDownEnd', 'userCheck'],
  components: {
    Modal,
    VueCountdown
  },
  data() {
    return {
      showModal: false,
      activeTip: {},
      balanceModal: false
    };
  },
  computed: {
    ...mapState(useUserStore, ['userInfo', 'currentCategory', 'currentGame']),
    errors() {

    },
  },
  methods: {
    ...mapActions(useUserStore, ['checkGame', 'getUserInfo', 'setBackUrl']),
    tipModal(tip){
      if (this.userInfo.coins >= tip.price){
        this.activeTip = tip;
        this.showModal = true;
      }
      else {
        this.balanceModal = true
      }
    },
    modalClose() {
      this.showModal = false;
    },
    goToStore() {
      this.setBackUrl(this.$route.path);
      this.$router.push('/shop')
    },
    confirmClose() {
      this.showModal = false;
    },
    async tipActivate(tip){
      this.activeTip = tip;
      this.$emit('buy',this.activeTip)
      this.showModal = false;
    },
    check(){
      this.$emit('userCheck')
    },
    expTime(tip){
      let t1 = new Date();
      let t2 = new Date(tip.expires_at);
      return tip.seconds_before_expiration*1000;
    },
    transformSlotProps(props) {
      const formattedProps = {};

      Object.entries(props).forEach(([key, value]) => {
        formattedProps[key] = value < 10 ? `0${value}` : String(value);
      });

      return formattedProps;
    },
    onCountDownEnd(tip){
      this.$emit('countDownEnd',tip)
    }
  }
};
</script>
