<template>
<div class="page-content present-page-content">
  <div v-if="promoList && promoList.length > 0" :class="['shop-list-wrap present-list-wrap']">
    <div :class="['shop-list present-list']">
      <presentCard v-for="item in promoList" :item="item"  @clickShowModal="clickShowModal"></presentCard>
    </div>
  </div>
  <div  v-if="promoList && promoList.length === 0"  class="presentCard__empty">
    <p class="presentCard__emptyTitle">Подарков нет</p>
    <img class="presentCard__emptyImg" src="@/assets/img/present_zero.svg"  alt="">
  </div>

  <presentModal  @confirmClose="confirmClose()" :showPresentModal="showPresentModal" :currentItem="currentItem"></presentModal>
</div>
</template>

<script>
import { RouterLink, RouterView } from 'vue-router'
import Modal from '@/components/modals/Modal.vue'
import { mapState, mapActions } from 'pinia';
import { useUserStore } from '@/store/user';
import PresentCard from '@/components/present/PresentCard.vue'
import PresentModal from '@/components/present/PresentModal.vue';

export default {
  name: 'PresentView',
  components: {
    RouterLink,
    RouterView,
    Modal,
    PresentCard,
    PresentModal
  },
  async created() {
    await this.getPromo();
  },
  data() {
    return {
      showPresentModal: false,
      currentItem: null,
    };
  },
  computed: {
    ...mapState(useUserStore, ['userInfo', 'promo']),
    // thisIsChInIos() {
    //   const isCh = window.navigator.userAgent.indexOf('CriOS') === -1 ? false : true ;
    //   return isCh;
    // },
    // thisIsYA() {
    //   const YaBrowser = window.navigator.userAgent.indexOf('YaBrowser') === -1 ? false : true;
    //   const YaSearchBrowser = window.navigator.userAgent.indexOf('YaSearchBrowser') === -1 ? false : true;
    //   return YaBrowser || YaSearchBrowser;
    // },
    promoList() {
      if (this.promo){
        return this.promo.data;
      }
    },
  },
  methods: {
    ...mapActions(useUserStore, ['getPromo']),
    confirmClose() {
      this.showPresentModal = false;
      this.currentItem = null;
    },
    clickShowModal(item) {
      this.currentItem = item;
      this.showPresentModal = true;
    },
  }
};
</script>
