<template>
  <Modal
      v-model="noLivePopUp"
      @close="closeNoLivePopUp"
      @confirm="btnAction"
      title='Жизни закончились'
      :btnText="itsTrial ? 'Оформить подписку' : 'В магазин'"
      :class="['default-modal', {'error-modal noLives-modal' : itsTrial}]"
  >

    <div class="default-modal-mainContent">
        <div v-if="itsTrial" class="default-modal-icon default-modal-icon--noLiveTrial">
            <svg width="58" height="50" viewBox="0 0 104 90" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M81.1241 0.156157C76.5813 -0.321749 71.9945 0.300791 67.7183 1.97562L55.7928 17.8489L65.9804 26.9148L49.3805 50.7561L59.9277 68.8879L51.9993 90C72.2948 78.7695 87.4264 65.6046 97.3944 50.5051C104.046 39.4001 105.674 28.2951 102.278 17.1901C98.6828 7.44865 91.6313 1.77067 81.1241 0.156157ZM44.9459 7.37127L44.2867 6.80661C38.1661 1.64101 31.0288 -0.575811 22.8746 0.156157C12.3873 1.76649 5.33588 7.44447 1.72026 17.1901C-1.67163 28.2951 -0.0475984 39.4001 6.59236 50.5051C16.2646 65.1445 30.7771 77.9644 50.1296 88.9648L53.5155 69.233L39.1329 51.603L50.0697 25.5659L38.6835 18.7272L44.9459 7.37127Z" fill="white"/>
            </svg>    
        </div>
        <div v-else class="default-modal-icon default-modal-icon--noLive">
            <svg width="84" height="70" viewBox="0 0 84 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_i_4143_51724)">
                    <path d="M55.1505 1.1341C49.7307 3.19574 45.2252 7.11445 42.4422 12.1873C39.7947 7.09434 35.3505 3.15733 29.965 1.1341C26.9957 0.120034 23.8366 -0.220141 20.7184 0.138422C17.6003 0.496986 14.6019 1.54523 11.9424 3.20657C8.81368 5.22649 6.25732 8.01261 4.51778 11.2985C2.77823 14.5843 1.91368 18.26 2.0068 21.974C2.46892 31.968 7.59074 41.6779 17.3723 51.1039C24.1346 57.5516 30.7583 63.3085 37.2434 68.3745C38.8119 69.4338 40.6631 70 42.5578 70C44.4525 70 46.3036 69.4338 47.8721 68.3745C54.2648 63.3085 60.85 57.5516 67.6277 51.1039C77.4093 41.6626 82.5311 31.9526 82.9932 21.974C83.0863 18.26 82.2218 14.5843 80.4822 11.2985C78.7427 8.01261 76.1863 5.22649 73.0576 3.20657C70.4125 1.56258 67.4342 0.523963 64.3377 0.165582C61.2411 -0.1928 58.1033 0.137987 55.1505 1.1341Z" fill="white" style="mix-blend-mode:hard-light"/>
                </g>
                <defs>
                    <filter id="filter0_i_4143_51724" x="2" y="0" width="81" height="70" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset/>
                        <feGaussianBlur stdDeviation="3.5"/>
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.55 0"/>
                        <feBlend mode="hard-light" in2="shape" result="effect1_innerShadow_4143_51724"/>
                    </filter>
                </defs>
            </svg>
        </div>
        <div v-if="!itsTrial" class="default-modal-text " v-html="'Восстановятся через:'"></div>
        <div v-if="itsTrial" class="default-modal-text">Оформите подписку, чтобы получить доступ ко всем функциям сервиса</div>
        <div class="popup-reward-list default-modal-text default-modal-text--20pbMobile" v-if="userInfo.next_live_update_at && !itsTrial   ">
            <vue-countdown class="popup-countdown" v-if="timer" :time="expTime()" v-slot="{ days, hours, minutes, seconds }" :transform="transformSlotProps" @end="onCountDownEnd()">
                <span v-if="hours > 0">{{hours}}:</span>{{minutes}}<span v-if="hours < 1">:{{seconds}}</span>
            </vue-countdown>
        </div>
        <p v-if="!itsTrial" class='default-modal-text'>Дождитесь восставновления или купите их в магазине</p>
    </div>

    <template #customFooter>
        <div class='default-modal-footer--wrapper'> 
            <p v-if="itsTrial" class="default-modal-footer">Управлять услугой можно в приложении билайн (12+): <a href="http://www.beeline.ru/login">http://www.beeline.ru/login</a></p>
        </div>
    </template>

  </Modal>
  </template>
  
  
  <script>
    import { useModal } from 'vue-final-modal'
    import Modal from '@/components/modals/Modal.vue'
    import VueCountdown from '@chenfengyuan/vue-countdown';
    import { useUserStore } from '@/store/user';    
    import { mapActions, mapState } from 'pinia';


  export default {
    name: 'noLiveModal',
    components: {
      Modal,
      VueCountdown
    },
    props: {
        showNoLiveModal: {
            type: Boolean,
            default: false,
        }
    },
    data () {
      return {
        counting: false,
      }
    }, 
    computed: {
        ...mapState(useUserStore, ['userInfo', 'noLivePopUp', 'itsTrial']),
        showModal () {
            return this.showNoLiveModal;
        },
        timer(){
            if (this.userInfo.next_live_update_at){
                this.counting = true;
            }
            return this.counting;
        },
    },
    methods: {
        ...mapActions(useUserStore, ['getUserInfo', 'closeNoLivePopUp', 'changeShopType']),   
      goToStore() {
        this.closeNoLivePopUp();
        this.changeShopType('lives');
        this.$router.push({name: `Shop`})
      },
      transformSlotProps(props) {
      const formattedProps = {};

      Object.entries(props).forEach(([key, value]) => {
        formattedProps[key] = value < 10 ? `0${value}` : String(value);
      });

      return formattedProps;
    },
    expTime(){
        let now = new Date().getTime();
        if (this.userInfo.next_live_update_at && (new Date(this.userInfo.next_live_update_at).getTime() - now) > 0){
            return (new Date(this.userInfo.next_live_update_at).getTime() - now)
        }
        else {
            this.counting = false;
        }
    },
    btnAction () {
        if (this.itsTrial) {
            window.location.href = '/backend/subscribe_web';
        } else {
            this.goToStore();
        }
    },
    async onCountDownEnd(){
      const result = await this.getUserInfo();
      this.counting = false;
      this.closeNoLivePopUp();
    },
    },
  };
  </script>
  